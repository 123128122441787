import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Popup } from 'semantic-ui-react';
import { ModalActionButtons } from './modal-action-buttons';
import './styles.less';
import { Status } from '../../../components/status';
import NotepadIcon from '../../../components/icons/notepad.svg';

function ModalWindow({
	isModalShown,
	onClose,
	headerText,
	headerAdditionalText,
	actionButtons,
	modalContent,
	isScrolling,
	footerButtons,
	style,
	navItems,
	modalHeader,
	headerStatuses,
	onKeyDown,
	note,
	onOpenModal,
	isNoteShown,
}) {
	const noteContent = note ? (
		<Popup
			// eslint-disable-next-line react/no-danger
			content={<div dangerouslySetInnerHTML={{ __html: note }} />}
			trigger={
				// eslint-disable-next-line react/jsx-wrap-multilines
				<div className="modal-window__header-note-icon" onClick={() => onOpenModal('note')}>
					<img className="modal-window__header-note-image" src={NotepadIcon} alt="notepad-icon" />
				</div>
			}
			position="top center"
			size="mini"
			inverted
			style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
		/>
	) : (
		<div className="modal-window__header-note-icon" onClick={() => onOpenModal('note')}>
			<img className="modal-window__header-note-image" src={NotepadIcon} alt="notepad-icon" />
		</div>
	);

	return (
		<Modal open={isModalShown} onClose={onClose} className="modal-window" data-testid="modal" style={style}>
			{(headerText || actionButtons || modalHeader) && navItems?.length > 0 && (
				<div className="modal-window__nav">
					{navItems.map(({ id, text, onClick, isActive }) => (
						<div
							className={`${isActive ? 'modal-window__nav-item_active ' : ''}modal-window__nav-item`}
							onClick={onClick}
							key={id}
						>
							{text}
						</div>
					))}
				</div>
			)}
			{(headerText || actionButtons) && (
				<Modal.Header
					className={`modal-window__header ${headerAdditionalText ? 'modal-window__header_big' : ''}`}
				>
					<div className="modal-window__header-text">
						<div className="modal-window__header-block">
							<p
								className="modal-window__header-text-title"
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{ __html: headerText }}
							/>
							{isNoteShown && noteContent}
						</div>
						{headerAdditionalText && (
							<p className="modal-window__header-text-regular">{headerAdditionalText}</p>
						)}
					</div>

					<div className="modal-window__header-actions">
						{headerStatuses && (
							<div className="modal-window__header-statuses">
								{headerStatuses.map(status => (
									<Status status={status} key={status.name} isModalHeader />
								))}
							</div>
						)}
						{actionButtons && (
							<Modal.Actions>
								<ModalActionButtons buttons={actionButtons} />
							</Modal.Actions>
						)}
					</div>
				</Modal.Header>
			)}
			{modalHeader && <Modal.Header className="modal-window__header">{modalHeader}</Modal.Header>}
			{modalContent && (
				<Modal.Content
					onKeyDown={onKeyDown}
					scrolling={isScrolling}
					className={`${footerButtons ? 'modal-window__content_with-buttons' : ''}`}
				>
					{modalContent}
				</Modal.Content>
			)}

			{footerButtons && footerButtons}
		</Modal>
	);
}

ModalWindow.propTypes = {
	isModalShown: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	headerText: PropTypes.string,
	headerAdditionalText: PropTypes.string,
	actionButtons: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			onClick: PropTypes.func.isRequired,
			text: PropTypes.string.isRequired,
		}),
	),
	modalContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	onKeyDown: PropTypes.func,
	modalHeader: PropTypes.element,
	isScrolling: PropTypes.bool,
	footerButtons: PropTypes.object,
	style: PropTypes.object,
	navItems: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
			isActive: PropTypes.bool,
		}),
	),
	headerStatuses: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			name: PropTypes.string,
		}),
	),
	note: PropTypes.string,
	onOpenModal: PropTypes.func,
	isNoteShown: PropTypes.bool,
};

export { ModalWindow };
