import { SubmissionError } from 'redux-form';
import { REQUIRED_FIELDS_FOR_VALIDATE } from './constants';
import { goToElement } from '../../utils/goToElement';

export const getStringFromField = fieldValue => {
	return `${fieldValue ?? ''}` ? `${fieldValue ?? ''}%` : '-';
};

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		if (key === 'from_id') {
			result.shipper = value;
		}
		if (key === 'to_id') {
			result.carrier = value;
		}

		result[key] = value[0];

		return result;
	}, {});
};

export const validate = values => {
	const fields = REQUIRED_FIELDS_FOR_VALIDATE;

	fields.forEach(key => {
		if (!values?.[key]) {
			goToElement(key);
			throw new SubmissionError({ [key]: 'Заполните это поле' });
		}
	});
};
