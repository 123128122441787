/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectChosenCommentModalDomain = state => state.chosenCommentModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsChosenCommentLoadingSelector = () =>
	createSelector(selectChosenCommentModalDomain, ({ isChosenCommentLoading }) => isChosenCommentLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectChosenCommentModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'chosen_comment');
	});

export const getDataForRequestSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		return { comment: formValues?.chosen_comment };
	});

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectChosenCommentModalDomain,
		getFormValues(FORM_NAME),
		({ chosenComment, isChosenCommentLoading }, formValues) => {
			if (isChosenCommentLoading) {
				return {};
			}

			return {
				chosen_comment: formValues?.chosen_comment ?? chosenComment?.comment,
			};
		},
	);
