/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { AlertModalView } from '../alert-modal/alert-modal-view';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import saga from '../carrier-change-modal/saga';
import reducer from '../carrier-change-modal/reducer';
import { getCarriersInfoSelector, getIsModalApprovalShownSelector } from '../carrier-change-modal/selectors';
import { FormTextAreaField } from '../../semantic-ui/components/form-textarea-field';
import { changeCarrierAction } from '../carrier-change-modal/actions';

export const formName = 'add-carrier-approval-form';

const ApproveCarrierModalWrapper = ({ carriersInfo, isModalShown, onChangeCarrier, comment, onResetForm }) => {
	useInjectSaga({ key: 'carrierChangeModalSaga', saga });
	useInjectReducer({ key: 'carrierChangeModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const handleBack = () => {
		query.set('modal', 'carrier_change');
		query.delete('carrier-id');
		history.push(`${pathname}?${query}`);
		onResetForm(formName);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('id');
		query.delete('carrier-id');
		history.push(`${pathname}?${query}`);
		onResetForm(formName);
	};

	const handleChangeCarrier = () => {
		onChangeCarrier({
			redirect: handleModalClose,
			carrierId: query.get('carrier-id'),
			id: query.get('id'),
			searchQuery: query.toString(),
			comment,
		});
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			modalContent={
				<div>
					<AlertModalView
						title="Вы выбрали"
						text={
							carriersInfo[0] && `${carriersInfo[0].company.name}. Ставка: ${carriersInfo[0].price} с НДС`
						}
						bodyComponent={
							<>
								<div style={{ width: '70%', marginTop: '10px' }}>
									<Field
										name="comment"
										type="text"
										component={FormTextAreaField}
										autoComplete="off"
										placeholder="Добавьте примечание при необходимости"
										width="100%"
										resize="vertical"
									/>
								</div>
							</>
						}
						buttons={[
							{
								type: 'success',
								text: 'Да, выбрать',
								onClick: handleChangeCarrier,
							},
							{
								type: 'error',
								text: 'Нет',
								onClick: handleBack,
							},
						]}
						type="warning"
					/>
				</div>
			}
			style={{ width: '450px' }}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	carriersInfo: getCarriersInfoSelector(),
	isModalShown: getIsModalApprovalShownSelector(),
	comment: state => formValueSelector(formName)(state, 'comment'),
});

const mapDispatchToProps = {
	onChangeCarrier: changeCarrierAction,
	onResetForm: reset,
};

const withForm = reduxForm({
	form: formName,
	enableReinitialize: true,
})(ApproveCarrierModalWrapper);

const ApproveCarrierModal = connect(mapStateToProps, mapDispatchToProps)(withForm);
ApproveCarrierModalWrapper.propTypes = {
	isModalShown: PropTypes.bool.isRequired,
	carriersInfo: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			price: PropTypes.string.isRequired,
			company: PropTypes.shape({ name: PropTypes.string, inn: PropTypes.string }),
		}),
	).isRequired,
	onChangeCarrier: PropTypes.func.isRequired,
	comment: PropTypes.string,
	onResetForm: PropTypes.func.isRequired,
};

export default ApproveCarrierModal;
