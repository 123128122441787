import { SET_IS_FORM_SENDING, SET_IS_NOTE_LOADING, SET_NOTE } from './actions';

export const initialState = {
	note: null,
	isNoteLoading: false,
	isFormSending: false,
};

const noteModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_NOTE:
			return {
				...state,
				note: payload,
			};
		case SET_IS_NOTE_LOADING:
			return {
				...state,
				isNoteLoading: payload,
			};

		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		default:
			return state;
	}
};

export default noteModalReducer;
