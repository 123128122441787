import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

export const Status = ({
	status: { name, color, description },
	isFullwidth,
	isModalHeader,
	statusDetailsTimeLineComponent,
}) => {
	if (description) {
		return (
			<div className={`status__wrapper status_${color} ${isFullwidth ? ' status_fullwidth' : ''}`}>
				<div className={`status ${isModalHeader ? ' status_nowrap' : ''}`}>
					{name}
					{isModalHeader ? <br /> : <div className={`status__divider status__divider_${color}`} />}
					<div className="status__line">{description.join('\n')}</div>
				</div>
				<div className="status__status-details">{statusDetailsTimeLineComponent}</div>
			</div>
		);
	}

	return (
		<div className={`status__wrapper status_${color} ${isFullwidth ? ' status_fullwidth' : ''}`}>
			<div className="status ">{name}</div>
			<div className="status__status-details">{statusDetailsTimeLineComponent}</div>
		</div>
	);
};

Status.propTypes = {
	status: PropTypes.shape({
		name: PropTypes.string,
		color: PropTypes.string,
		description: PropTypes.string,
	}).isRequired,
	isFullwidth: PropTypes.bool,
	isModalHeader: PropTypes.bool,
	statusDetailsTimeLineComponent: PropTypes.node,
};
