import React from 'react';
import PropTypes from 'prop-types';
import { TRAILER_TABLE_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { TrailerForm } from '../trailer-form';

export const TrailerContent = ({ trailerInfo, isTrailerInfoLoading, isTrailerFormOpen }) => {
	return isTrailerFormOpen ? (
		<TrailerForm />
	) : (
		<SimpleTableComponent
			rows={trailerInfo}
			isTableDataLoading={isTrailerInfoLoading}
			columns={TRAILER_TABLE_COLUMNS_NAMES}
		/>
	);
};

TrailerContent.propTypes = {
	trailerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
				}),
			]).isRequired,
		}),
	),
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTrailerFormOpen: PropTypes.bool.isRequired,
};
