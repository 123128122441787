/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Popup } from 'semantic-ui-react';
import { ReqStatusActionsView } from './req-status-view';
import { Timeline } from '../../../../components/timeline';

export const ReqStatusActionsComponent = ({
	status,
	actions,
	onActionButtonClick,
	reqId,
	badges,
	timeline,
	withLabels,
}) => {
	const { search } = useLocation();

	const query = new URLSearchParams(search);

	return (
		<ReqStatusActionsView
			status={status}
			withLabels={withLabels}
			actions={actions}
			onActionButtonClick={onActionButtonClick}
			reqId={reqId}
			query={query.toString()}
			badges={badges}
			statusDetailsTimeLineComponent={
				timeline && (
					<Popup
						content={<Timeline timeline={timeline} />}
						trigger={<div className="info-icon" />}
						position="left center"
						size="mini"
						inverted
						style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
					/>
				)
			}
		/>
	);
};

ReqStatusActionsComponent.propTypes = {
	status: PropTypes.shape({
		name: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		description: PropTypes.string,
		link: PropTypes.string,
	}),
	actions: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.bool), PropTypes.array]),
	onActionButtonClick: PropTypes.func.isRequired,
	reqId: PropTypes.number.isRequired,
	badges: PropTypes.objectOf(
		PropTypes.shape({
			count: PropTypes.number.isRequired,
			color: PropTypes.string.isRequired,
		}),
	),
	timeline: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string.isRequired,
			current: PropTypes.bool.isRequired,
			uid: PropTypes.string.isRequired,
			names: PropTypes.arrayOf(
				PropTypes.shape({ name: PropTypes.string.isRequired, company_type: PropTypes.string.isRequired }),
			).isRequired,
		}),
	).isRequired,
	withLabels: PropTypes.bool,
};
