/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Fragment } from 'react';
import './styles.less';
import { Grid } from 'semantic-ui-react';
import { Field } from 'redux-form';
import AddButton from '../../../../components/images/add-button.svg';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import { CustomDatePicker } from '../../../../semantic-ui/components/custom-datepicker';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';

export const ModesComponent = ({ fields, isCarInfoLoading, isModesLoading, modes }) => {
	return fields.map((name, index) => {
		return (
			<Fragment key={`${name}.modes`}>
				<Grid.Row className="modes__row">
					<Grid.Column width={6} className="modes__column">
						Температурный режим №{index + 1}
					</Grid.Column>
					<Grid.Column className="modes__mode">
						<Field
							name={`${name}.mode_id`}
							type="select"
							component={FormSelectField}
							autoComplete="off"
							search
							placeholder="°C"
							options={modes || []}
							dropdownClassName="modes__dropdown"
							loading={isCarInfoLoading || isModesLoading}
						/>
					</Grid.Column>
					<Grid.Column className="modes__validate">
						<span className="modes__text">Валидация</span>
						<Field name={`${name}.is_validated`} component={FormCheckboxField} toggle type="checkbox" />
					</Grid.Column>
					<Grid.Column className="modes__date">
						<span className="modes__text">Действительна до</span>
						<Field
							name={`${name}.date`}
							type="text"
							component={CustomDatePicker}
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
							autoComplete="off"
							placeholder=". . / . . / . ."
							loading={isCarInfoLoading}
						/>
					</Grid.Column>
					<Grid.Column>
						{index === fields.length - 1 && (
							<div
								className="modes__column_close"
								onClick={() => {
									fields.remove(index);
									if (index === 0) {
										fields.push();
									}
								}}
							/>
						)}
					</Grid.Column>
					<Field
						name={`${name}.modes`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return (
									<>
										<Grid.Column width={6} />
										<Grid.Column width={10}>
											<div className="modes__error-message">{meta.error}</div>
										</Grid.Column>
									</>
								);
							}

							return null;
						}}
					/>
				</Grid.Row>
				{index === fields.length - 1 && index < 7 && (
					<div className="modes__button-row">
						<div className="modes__button-row-text">Добавить температурный режим</div>
						<img src={AddButton} alt="add-button" onClick={() => fields.push({})} />
					</div>
				)}
			</Fragment>
		);
	});
};
