/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './styles.less';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import MailLogo from '../../../../components/images/mail.svg';
import CloseImg from '../../../../components/images/arrow-right-with-pipe.svg';

const ChatsListView = ({ chatsList, onGoToReq, isChatsListLoading, onChatsListClose }) => {
	return (
		<div className="chats-list">
			{isChatsListLoading && <Loader active />}
			<div className="chats-list__close-btn" onClick={onChatsListClose}>
				<img src={CloseImg} alt="close" />
			</div>
			<header className="chats-list__header">
				<div className="chats-list__header-section">
					<img className="chats-list__header-img" src={MailLogo} alt="mail" />
					Чаты
				</div>
			</header>
			{chatsList.length > 0 ? (
				<ul className="chats-list__list">
					{chatsList.map(chat => (
						<li onClick={() => onGoToReq(chat.id)} className="chats-list__list-item" key={chat.id}>
							<header className="chats-list__list-item-header">
								<div className="chats-list__list-item-title">
									{chat.badge > 0 && <div className="chats-list__badge">{chat.badge}</div>}
									<img className="chats-list__header-img" src={MailLogo} alt="mail" />
									Заявка № {chat.id}
								</div>
								<div className="chats-list__list-item-actions">
									<div className="chats-list__date">{chat.date}</div>
								</div>
							</header>
							<div className="chats-list__list-item-text">
								<div
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{ __html: chat.message }}
								/>
							</div>
						</li>
					))}
				</ul>
			) : (
				<div className="chats-list__empty-message">Новых сообщений нет</div>
			)}
		</div>
	);
};

ChatsListView.propTypes = {
	chatsList: PropTypes.arrayOf(
		PropTypes.shape({
			badge: PropTypes.number.isRequired,
			date: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			message: PropTypes.string.isRequired,
		}),
	).isRequired,
	onGoToReq: PropTypes.func.isRequired,
	isChatsListLoading: PropTypes.bool.isRequired,
	onChatsListClose: PropTypes.func.isRequired,
};

export { ChatsListView };
