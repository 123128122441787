export const GET_CHOSEN_COMMENT = 'chosen-comment/GET_CHOSEN_COMMENT';
export const getChosenCommentAction = payload => ({
	type: GET_CHOSEN_COMMENT,
	payload,
});

export const SET_CHOSEN_COMMENT = 'chosen-comment/SET_CHOSEN_COMMENT';
export const setChosenCommentAction = payload => ({
	type: SET_CHOSEN_COMMENT,
	payload,
});

export const SET_IS_CHOSEN_COMMENT_LOADING = 'chosen-comment/SET_IS_CHOSEN_COMMENT_LOADING';
export const setIsChosenCommentLoadingAction = payload => ({
	type: SET_IS_CHOSEN_COMMENT_LOADING,
	payload,
});

export const SEND_COMMENT = 'chosen-comment/SEND_COMMENT';
export const sendCommentAction = payload => ({
	type: SEND_COMMENT,
	payload,
});

export const SET_IS_FORM_SENDING = 'chosen-comment/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
