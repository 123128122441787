export const GET_RELATIONSHIP_INFO = 'relationship-modal/GET_RELATIONSHIP_INFO';
export const getRelationshipInfoAction = id => ({
	type: GET_RELATIONSHIP_INFO,
	id,
});

export const SET_RELATIONSHIP_INFO = 'relationship-modal/SET_RELATIONSHIP_INFO';
export const setRelationshipInfoAction = payload => ({
	type: SET_RELATIONSHIP_INFO,
	payload,
});

export const SET_IS_RELATIONSHIP_INFO_LOADING = 'relationship-modal/SET_IS_RELATIONSHIP_INFO_LOADING';
export const setIsRelationshipInfoLoadingAction = payload => ({
	type: SET_IS_RELATIONSHIP_INFO_LOADING,
	payload,
});

export const DELETE_RELATIONSHIP = 'relationship-modal/DELETE_RELATIONSHIP';
export const deleteRelationshipAction = payload => ({
	type: DELETE_RELATIONSHIP,
	payload,
});

export const SET_IS_RELATIONSHIP_DELETING = 'relationship-modal/SET_IS_RELATIONSHIP_DELETING';
export const setIsRelationshipDeletingAction = payload => ({
	type: SET_IS_RELATIONSHIP_DELETING,
	payload,
});

export const GET_SHIPPERS_LIST = 'relationship-modal/GET_SHIPPERS_LIST';
export const getShippersListAction = payload => ({
	type: GET_SHIPPERS_LIST,
	payload,
});

export const SET_SHIPPERS_LIST = 'relationship-modal/SET_SHIPPERS_LIST';
export const setShippersListAction = payload => ({
	type: SET_SHIPPERS_LIST,
	payload,
});

export const SET_IS_SHIPPERS_LIST_LOADING = 'relationship-modal/SET_IS_SHIPPERS_LIST_LOADING';
export const setIsShippersListLoadingAction = payload => ({
	type: SET_IS_SHIPPERS_LIST_LOADING,
	payload,
});

export const CREATE_RELATIONSHIP = 'relationship-modal/CREATE_RELATIONSHIP';
export const createRelationshipAction = payload => ({
	type: CREATE_RELATIONSHIP,
	payload,
});

export const UPDATE_RELATIONSHIP = 'relationship-modal/UPDATE_RELATIONSHIP';
export const updateRelationshipAction = payload => ({
	type: UPDATE_RELATIONSHIP,
	payload,
});

export const SET_IS_RELATIONSHIP_SAVING = 'relationship-modal/SET_IS_RELATIONSHIP_SAVING';
export const setIsRelationshipSavingAction = payload => ({
	type: SET_IS_RELATIONSHIP_SAVING,
	payload,
});

export const GET_CARRIERS_LIST = 'relationship-modal/GET_CARRIERS_LIST';
export const getCarriersListAction = payload => ({
	type: GET_CARRIERS_LIST,
	payload,
});

export const SET_CARRIERS_LIST = 'relationship-modal/SET_CARRIERS_LIST';
export const setCarriersListAction = payload => ({
	type: SET_CARRIERS_LIST,
	payload,
});

export const SET_IS_CARRIERS_LIST_LOADING = 'relationship-modal/SET_IS_CARRIERS_LIST_LOADING';
export const setIsCarriersListLoadingAction = payload => ({
	type: SET_IS_CARRIERS_LIST_LOADING,
	payload,
});

export const RESET_STATE = 'relationship-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
