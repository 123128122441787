import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../../semantic-ui/components/form-select-field';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { ModesComponent } from '../../modes';

export const TrailerFormView = ({
	isTrailerInfoLoading,
	tonnages,
	isTonnagesLoading,
	trailerTypes,
	isTypeDateIssueVisible,
	modes,
	isAdmin,
	isTrailerTypesLoading,
	isModesLoading,
}) => {
	return (
		<Grid className="trailer-form">
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Модель
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите модель"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Гос. номер
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="number"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите гос. номер"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Vin номер
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="vin"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите vin номер"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Тип прицепа
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="type_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={trailerTypes}
						search
						dropdownClassName="static"
						loading={isTrailerInfoLoading || isTrailerTypesLoading}
						noResultsMessage="Не найдено"
						placeholder="Укажите тип прицепа"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Год выпуска прицепа
				</Grid.Column>
				<Grid.Column width={3} className="trailer-form__column">
					<Field
						name="date_issue"
						type="text"
						component={CustomDatePicker}
						autoComplete="off"
						placeholder=". . / . . "
						dateFormat={['MM.yyyy', 'MM-yyyy', 'MM/yyyy']}
						loading={isTrailerInfoLoading}
						showMonthYearPicker
					/>
				</Grid.Column>
			</Grid.Row>
			{isTypeDateIssueVisible && (
				<Grid.Row className="trailer-form__row">
					<Grid.Column width={6} className="trailer-form__column">
						Год выпуска рефрижератора
					</Grid.Column>
					<Grid.Column width={3} className="trailer-form__column">
						<Field
							name="type_date_issue"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder=". . / . . "
							dateFormat={['MM.yyyy', 'MM-yyyy', 'MM/yyyy']}
							loading={isTrailerInfoLoading}
							showMonthYearPicker
						/>
					</Grid.Column>
				</Grid.Row>
			)}
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={6} className="trailer-form__column">
					Грузоподъемность
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column">
					<Field
						name="tonnage_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={tonnages}
						search
						dropdownClassName="static"
						loading={isTonnagesLoading || isTrailerInfoLoading}
						disabled={isTonnagesLoading || isTrailerInfoLoading}
						noResultsMessage="Не найдено"
						placeholder="Укажите грузоподъемность прицепа (тн)"
						isWithoutSelectOnBlur
					/>
				</Grid.Column>
			</Grid.Row>
			{isTypeDateIssueVisible && (
				<FieldArray
					name="modes"
					component={ModesComponent}
					props={{ modes, isTrailerInfoLoading, isModesLoading }}
				/>
			)}
			{isAdmin && (
				<Grid.Row className="trailer-form__row">
					<Grid.Column width={6} className="trailer-form__column">
						Прицеп аккредитован
					</Grid.Column>
					<Grid.Column width={7} className="trailer-form__column">
						<Field name="is_accredited" component={FormCheckboxField} toggle type="checkbox" />
					</Grid.Column>
				</Grid.Row>
			)}
		</Grid>
	);
};

TrailerFormView.propTypes = {
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	trailerTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isTypeDateIssueVisible: PropTypes.bool.isRequired,
	modes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isAdmin: PropTypes.bool.isRequired,
	isTrailerTypesLoading: PropTypes.bool.isRequired,
	isModesLoading: PropTypes.bool.isRequired,
};
