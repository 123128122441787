import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { acceptTermsRequest } from '../../api/requests';
import { DISMISS_TIME } from '../../api/constants';
import { notificationInit } from '../../modules/notifications/actions';
import { errorHandler } from '../../api/utils';
import { ACCEPT_TERMS, setIsFormSendingAction } from './actions';
import { getDataForSaveSelector } from './selectors';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';
import { setUserInfoAction } from '../profile/actions';

export function* acceptTermsSaga({ payload: redirect }) {
	try {
		yield put(setIsFormSendingAction(true));

		const dataForSave = yield select(getDataForSaveSelector());

		const { data, message, toast } = yield call(acceptTermsRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			if (data.user.accepted_terms_at) {
				const user_data = getLocalStorageItem('user_data');

				setLocalStorageItem('user_data', { ...user_data, accepted_terms_at: data.user.accepted_terms_at });
				yield put(setUserInfoAction(data.user));

				redirect('/home/information');
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsFormSendingAction(false));
	}
}

export default function* acceptTermsPageSaga() {
	yield takeEvery(ACCEPT_TERMS, acceptTermsSaga);
}
