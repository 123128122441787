/* eslint-disable no-mixed-spaces-and-tabs */
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { GET_NOTE, SEND_NOTE, setIsFormSendingAction, setIsNoteLoadingAction, setNoteAction } from './actions';
import { FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { getDataForRequestSelector, getFormIsInvalidSelector } from './selectors';
import { getRawPaginationSelector, getReqsSelector } from '../reqs-list/selectors';
import { setPaginationAction, setReqsAction } from '../reqs-list/actions';
import { transformToValidationErrors } from './utils';
import { errorHandler } from '../../api/utils';
import { getSelectCompanyNoteRequest, setCompanyNoteRequest } from '../../api/requests';

export function* getNoteSaga({ payload: id }) {
	try {
		yield put(setIsNoteLoadingAction(true));

		const { data, message, toast } = yield call(getSelectCompanyNoteRequest, id);
		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setNoteAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsNoteLoadingAction(false));
	}
}

export function* sendNoteSaga({ payload: { redirect, id, searchQuery } }) {
	try {
		yield put(setIsFormSendingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForRequest = yield select(getDataForRequestSelector());

		const { data, message, toast, errors } = yield call(setCompanyNoteRequest, id, dataForRequest);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? {
									link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}`,
							  }
							: {}),
					}),
				);
			}

			const reqs = yield select(getReqsSelector());

			let newReqs = [];

			if (data.routes.includes(window.location.pathname)) {
				const updatedReq = {
					...data,
					link: `${window.location.pathname}?${searchQuery ? `&${searchQuery}` : ''}modal=reqs_details&id=${
						data.id
					}`,
					additionalPoints: data.points.length - 2,
					renderPoints: [data.points[0], data.points.at(-1)],
				};

				newReqs = reqs.map(req => {
					return req.id === data.id ? updatedReq : req;
				});
			} else {
				newReqs = reqs.filter(req => {
					return req.id !== data.id;
				});

				const pagination = yield select(getRawPaginationSelector());

				if (pagination) {
					yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
				}
			}

			yield put(setReqsAction(newReqs));

			redirect();
		} else {
			if (errors) {
				yield put(touch(FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsFormSendingAction(false));
	}
}

export default function* noteModalSaga() {
	yield takeEvery(GET_NOTE, getNoteSaga);
	yield takeEvery(SEND_NOTE, sendNoteSaga);
}
