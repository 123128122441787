export const REGISTRATION_FORM_NAME = 'registration-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TOGGLE_MODAL_STYLE = { width: '505px', height: '272px' };

export const REGISTRATION_MODAL_STYLE = { width: '900px' };

export const REQUIRED_FIELDS = [
	'company.name',
	'company.inn',
	'company.ogrn',
	'company.address',
	'contact.last_name',
	'contact.first_name',
	'contact.phone',
	'contact.email',
	'user.post',
	'user.last_name',
	'user.first_name',
	'user.email',
	'user.phone',
	'user.password',
	'user.password_confirmation',
	'user.is_accepted_personal_data_docs',
	'user.is_accepted_conditions_docs',
];

export const ORGANIZATION_DOCUMENTS = {
	POLICY: 'https://docs.mak-logic.ru/personal_data_policy.pdf',
	AGREEMENT: 'https://docs.mak-logic.ru/personal_data_approval.pdf',
	NOTIFICATION: 'https://docs.mak-logic.ru/notification_cookie.pdf',
	CONDITIONS: 'https://docs.mak-logic.ru/conditions.pdf',
};
