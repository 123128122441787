import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { getCarsListRequest } from '../../api/requests';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { GET_CARS_LIST, setCarsListAction, setIsCarsListLoadingAction, setPaginationAction } from './actions';
import { errorHandler } from '../../api/utils';

export function* getCarsListSaga({ payload: searchQuery }) {
	try {
		yield put(setIsCarsListLoadingAction(true));
		const { data, meta, message, toast } = yield call(getCarsListRequest, searchQuery);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setCarsListAction(
					data?.map(car => ({
						...car,
						type_name: car.type?.name ?? '---',
						link: `/home/cars?${searchQuery ? `${searchQuery}&` : ''}modal=car&id=${car.id}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCarsListLoadingAction(false));
	}
}

export default function* carsContainerSaga() {
	yield takeEvery(GET_CARS_LIST, getCarsListSaga);
}
