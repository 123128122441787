import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import {
	GET_USERS_LIST,
	GET_USER_INFO,
	SAVE_PROFILE,
	setChatsNotificationsCountAction,
	setIsProfileSavingAction,
	setIsUserInfoLoadingAction,
	setIsUsersListLoadingAction,
	setNotificationsCountAction,
	setUserInfoAction,
	setUsersListAction,
} from './actions';
import { getUserBadgesRequest, getUserInfoRequest, getUsersListRequest, setUserInfoRequest } from '../../api/requests';
import { FIELDS_FOR_VALIDATE, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { getDataForSaveProfileSelector, getFormIsInvalidSelector, getUserIdSelector } from './selectors';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import { createSocketConnection } from '../../api/sockets';
import { socketChatCountUpdateSaga, socketNotificationsSaga } from '../right-header-content/saga';
import {
	socketReqCountUpdateSaga,
	socketReqUpdateSaga,
	socketReqsUpdateSaga,
	socketReqTableUpdateSaga,
	socketTimerUpdateSaga,
} from '../reqs-list/saga';
import { errorHandler } from '../../api/utils';
import { socketReqMessageCreateSaga, socketReqMessagesUpdateSaga } from '../chat-modal/saga';
import { COMPANIES_TYPES_BY_ID } from '../login/constants';
import { setLocalStorageItem } from '../../utils/localStorage';

export function* getUserInfoSaga({ payload: history }) {
	try {
		yield put(setIsUserInfoLoadingAction(true));

		const badges = yield call(getUserBadgesRequest);

		const { data, message, toast } = yield call(getUserInfoRequest);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const userData = {
				last_name: data.user?.last_name || '-',
				first_name: data.user?.first_name || '-',
				email: data.user?.email || '-',
				company: data.user?.company?.name || '-',
				companyId: data.user?.company?.id || '-',
				isCompanyValidated: Boolean(data.user?.company?.is_validated),
				isCompanyAccredited: Boolean(data.user?.company?.is_accredited),
				email_verified_at: data.user?.email_verified_at,
				accepted_terms_at: data.user?.accepted_terms_at,
				company_type: COMPANIES_TYPES_BY_ID[data.user?.company?.type?.id] || '',
			};

			data.user.badges = badges;

			setLocalStorageItem('user_data', userData);
			yield put(setUserInfoAction(data.user));
			yield put(setNotificationsCountAction(data.user?.badges.notifications || 0));
			yield put(setChatsNotificationsCountAction(data.user?.badges.chats || 0));
			// socket sagas
			createSocketConnection();
			yield fork(socketNotificationsSaga);
			yield fork(socketReqUpdateSaga);
			yield fork(socketReqsUpdateSaga);
			yield fork(socketReqTableUpdateSaga);
			yield fork(socketReqCountUpdateSaga);
			yield fork(socketChatCountUpdateSaga);
			yield fork(socketReqMessageCreateSaga);
			yield fork(socketReqMessagesUpdateSaga);
			yield fork(socketTimerUpdateSaga);

			if (history?.location?.pathname?.includes('/email/verify') && data.user?.email_verified_at) {
				history?.push?.('/home/information');
			}

			if (history?.location?.pathname?.includes('/accept-terms') && data.user?.accepted_terms_at) {
				history?.push?.('/home/information');
			}

			if (
				history?.location?.pathname?.includes('/company/accredited') &&
				data.user?.company?.isCompanyAccredited
			) {
				history?.push?.('/home/information');
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserInfoLoadingAction(false));
	}
}

export function* saveProfileSaga({ payload: { redirect } }) {
	try {
		yield put(setIsProfileSavingAction(true));

		const dataForSave = yield select(getDataForSaveProfileSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(setUserInfoRequest, dataForSave);
		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			redirect();
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsProfileSavingAction(false));
	}
}

export function* getUsersListSaga() {
	try {
		yield put(setIsUsersListLoadingAction(true));
		const { data, message, toast } = yield call(getUsersListRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const userId = yield select(getUserIdSelector());

			yield put(
				setUsersListAction(
					data.reduce((list, { id, name }) => {
						if (+userId !== id) {
							list.push({
								text: name,
								key: id,
								value: id,
							});
						}

						return list;
					}, []),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUsersListLoadingAction(false));
	}
}

export default function* profileModalSaga() {
	yield takeEvery(GET_USER_INFO, getUserInfoSaga);
	yield takeEvery(SAVE_PROFILE, saveProfileSaga);
	yield takeEvery(GET_USERS_LIST, getUsersListSaga);
}
