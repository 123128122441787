import React from 'react';
import PropTypes from 'prop-types';
import { TABLE_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { RelationshipForm } from '../relationship-form';

export const RelationshipContent = ({ relationshipInfo, isRelationshipInfoLoading, isRelationshipFormOpen }) => {
	return isRelationshipFormOpen ? (
		<RelationshipForm />
	) : (
		<SimpleTableComponent
			rows={relationshipInfo}
			isTableDataLoading={isRelationshipInfoLoading}
			columns={TABLE_COLUMNS_NAMES}
		/>
	);
};

RelationshipContent.propTypes = {
	relationshipInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}),
	),
	isRelationshipInfoLoading: PropTypes.bool.isRequired,
	isRelationshipFormOpen: PropTypes.bool.isRequired,
};
