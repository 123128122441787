import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { change, reduxForm } from 'redux-form';
import {
	getCompaniesOptionsSelector,
	getCompaniesSearchValueSelector,
	getCompaniesSelector,
	getCompaniesTypesSelector,
	getFormInitialValuesSelector,
	getIsCompaniesLoadingSelector,
	getIsCompaniesTypesLoadingSelector,
	getIsCompanyInfoLoadingSelector,
	getIsProfileCompanyFormSelector,
} from '../../selectors';
import { CompanyFormView } from './company-form-vew';
import { FORM_NAME } from '../../constants';
import { getCompaniesAction, setCompaniesAction, setCompaniesSearchValueAction } from '../../actions';

const CompanyFormWrapper = ({
	isCompanyInfoLoading,
	companiesTypes,
	isCompaniesTypesLoading,
	companiesOptions,
	companiesSearchValue,
	isCompaniesLoading,
	isProfileCompanyForm,
	companies,
	onFieldChange,
	onGetCompanies,
	onSetCompaniesSearchValue,
	onSetCompanies,
}) => {
	useEffect(() => {
		return () => {
			onSetCompaniesSearchValue('');
			onSetCompanies([]);
		};
	}, []);

	const handleCompanySearchChange = ({ target: { value } }) => {
		onSetCompaniesSearchValue(value);
		onGetCompanies();
	};

	const handleCompanyChange = value => {
		const currentCompany = companies.find(({ data }) => data.inn === value);

		if (currentCompany) {
			onFieldChange(FORM_NAME, 'company.name', currentCompany.value);
			onFieldChange(FORM_NAME, 'company.inn', currentCompany.data?.inn);
			onFieldChange(FORM_NAME, 'company.ogrn', currentCompany.data?.ogrn);
			onFieldChange(FORM_NAME, 'company.address', currentCompany.data?.address?.value);
			onSetCompaniesSearchValue(currentCompany.value);
		}
	};

	return (
		<CompanyFormView
			isCompanyInfoLoading={isCompanyInfoLoading}
			companiesTypes={companiesTypes}
			isCompaniesTypesLoading={isCompaniesTypesLoading}
			companiesOptions={companiesOptions}
			onCompanySearchChange={handleCompanySearchChange}
			onCompanyChange={handleCompanyChange}
			companiesSearchValue={companiesSearchValue}
			isCompaniesLoading={isCompaniesLoading}
			isProfileCompanyForm={isProfileCompanyForm}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(CompanyFormWrapper);

const mapStateToProps = createStructuredSelector({
	isCompanyInfoLoading: getIsCompanyInfoLoadingSelector(),
	companiesTypes: getCompaniesTypesSelector(),
	isCompaniesTypesLoading: getIsCompaniesTypesLoadingSelector(),
	companiesOptions: getCompaniesOptionsSelector(),
	companiesSearchValue: getCompaniesSearchValueSelector(),
	isCompaniesLoading: getIsCompaniesLoadingSelector(),
	isProfileCompanyForm: getIsProfileCompanyFormSelector(),
	companies: getCompaniesSelector(),
	initialValues: getFormInitialValuesSelector(),
});

const mapDispatchToProps = {
	onFieldChange: change,
	onGetCompanies: getCompaniesAction,
	onSetCompaniesSearchValue: setCompaniesSearchValueAction,
	onSetCompanies: setCompaniesAction,
};

const CompanyForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

CompanyFormWrapper.propTypes = {
	isCompaniesTypesLoading: PropTypes.bool.isRequired,
	isCompanyInfoLoading: PropTypes.bool.isRequired,
	companiesTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
	companiesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isCompaniesLoading: PropTypes.bool.isRequired,
	companiesSearchValue: PropTypes.string.isRequired,
	isProfileCompanyForm: PropTypes.bool.isRequired,
	companies: PropTypes.array.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	onGetCompanies: PropTypes.func.isRequired,
	onSetCompaniesSearchValue: PropTypes.func.isRequired,
	onSetCompanies: PropTypes.func.isRequired,
};

export { CompanyForm };
