import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import { CarFormView } from './car-form-view';
import {
	getCarTypesSelector,
	getIsAdminSelector,
	getIsAvailableTrailersSelector,
	getIsCarInfoLoadingSelector,
	getIsCarTypesLoadingSelector,
	getIsModesLoadingSelector,
	getIsTonnagesLoadingSelector,
	getIsTrailersListLoadingSelector,
	getIsTrailerVisibleSelector,
	getIsTypeDateIssueVisibleSelector,
	getModesSelector,
	getTonnagesSelector,
	getTrailersListSelector,
} from '../../selectors';

export const CarFormWrapper = ({
	trailersList,
	isTrailersListLoading,
	isCarInfoLoading,
	tonnages,
	isTonnagesLoading,
	carTypes,
	isTypeDateIssueVisible,
	isTrailerVisible,
	modes,
	isAdmin,
	isCarTypesLoading,
	isModesLoading,
	isAvailableTrailers,
}) => {
	const history = useHistory();

	const handleAddTrailerButtonClick = () => {
		history.push('/home/cars?modal=trailer&mode=add');
	};

	return (
		<CarFormView
			trailersList={trailersList}
			isTrailersListLoading={isTrailersListLoading}
			onAddTrailerButtonClick={handleAddTrailerButtonClick}
			isCarInfoLoading={isCarInfoLoading}
			tonnages={tonnages}
			isTonnagesLoading={isTonnagesLoading}
			carTypes={carTypes}
			isTypeDateIssueVisible={isTypeDateIssueVisible}
			isTrailerVisible={isTrailerVisible}
			modes={modes}
			isAdmin={isAdmin}
			isCarTypesLoading={isCarTypesLoading}
			isModesLoading={isModesLoading}
			isAvailableTrailers={isAvailableTrailers}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isCarInfoLoading: getIsCarInfoLoadingSelector(),
	trailersList: getTrailersListSelector(),
	isTrailersListLoading: getIsTrailersListLoadingSelector(),
	tonnages: getTonnagesSelector(),
	isTonnagesLoading: getIsTonnagesLoadingSelector(),
	carTypes: getCarTypesSelector(),
	isTypeDateIssueVisible: getIsTypeDateIssueVisibleSelector(),
	isTrailerVisible: getIsTrailerVisibleSelector(),
	modes: getModesSelector(),
	isAdmin: getIsAdminSelector(),
	isCarTypesLoading: getIsCarTypesLoadingSelector(),
	isModesLoading: getIsModesLoadingSelector(),
	isAvailableTrailers: getIsAvailableTrailersSelector(),
});

CarFormWrapper.propTypes = {
	trailersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			key: PropTypes.string.isRequired,
		}),
	),
	isTrailersListLoading: PropTypes.bool.isRequired,
	isCarInfoLoading: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	carTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isTypeDateIssueVisible: PropTypes.bool.isRequired,
	isTrailerVisible: PropTypes.bool.isRequired,
	modes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isAdmin: PropTypes.bool.isRequired,
	isCarTypesLoading: PropTypes.bool.isRequired,
	isModesLoading: PropTypes.bool.isRequired,
	isAvailableTrailers: PropTypes.bool.isRequired,
};

const CarForm = connect(mapStateToProps)(CarFormWrapper);

export { CarForm };
