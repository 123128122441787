/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { selectProfileModalDomain } from '../profile/selectors';

export const selectRelationshipContainerDomain = state => state.relationshipContainerReducer || initialState;

export const getIsRelationshipListLoadingSelector = () =>
	createSelector(selectRelationshipContainerDomain, ({ isRelationshipListLoading }) => isRelationshipListLoading);

export const getRelationshipListSelector = () =>
	createSelector(selectRelationshipContainerDomain, ({ relationshipList }) => {
		if (!relationshipList || relationshipList.length === 0) {
			return [];
		}

		return relationshipList.map(relationship => ({
			...relationship,
			id: relationship.id,
			shipper: relationship.from?.name || '-',
			carrier: relationship.to?.name || '-',
			contract: relationship.contract || '-',
			conditions: relationship.conditions || '-',
		}));
	});

export const getPaginationSelector = () =>
	createSelector(selectRelationshipContainerDomain, ({ pagination }) => {
		if (!pagination || pagination?.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getTableSearchSelector = () =>
	createSelector(selectRelationshipContainerDomain, ({ tableSearch }) => tableSearch);

export const getRawPaginationSelector = () =>
	createSelector(selectRelationshipContainerDomain, ({ pagination }) => pagination);

export const getUnformattedRelationshipListSelector = () =>
	createSelector(selectRelationshipContainerDomain, ({ relationshipList }) => relationshipList);

export const getCompanyIdSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo?.company?.id);
