import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import './styles.less';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import StatusButton from '../../../../components/status-button';
import { FILTER_FORM_NAME } from '../../constants';
import { CustomDatePicker } from '../../../../semantic-ui/components/custom-datepicker';
import { normalizeNumber } from '../../../../utils/normalizeNumber';

export const ReqFilterComponent = ({ onSearchButtonClick, formOptions, onKeyDown }) => {
	return (
		<div onKeyDown={onKeyDown} className="req-filter-component">
			<Grid>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="search"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Поиск"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<hr />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="region.from"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							noResultsMessage="Не найдено"
							placeholder="Откуда"
							options={formOptions?.from || []}
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="region.to"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							noResultsMessage="Не найдено"
							placeholder="Куда"
							multiple
							options={formOptions?.to || []}
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<hr />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="mode"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							multiple
							noResultsMessage="Не найдено"
							placeholder="Режим перевозки"
							options={formOptions?.mode || []}
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<Field
							name="type"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							noResultsMessage="Не найдено"
							placeholder="Тип отгрузки"
							options={formOptions?.type || []}
							isWithoutSelectOnBlur
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<hr />
					</Grid.Column>
				</Grid.Row>
				{Boolean(formOptions?.status) && (
					<Grid.Row className="req-filter-component__row">
						<Grid.Column width={16}>
							<Field
								name="status"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								search
								noResultsMessage="Не найдено"
								placeholder="Статус"
								options={formOptions?.status || []}
								isWithoutSelectOnBlur
							/>
						</Grid.Column>
					</Grid.Row>
				)}
				{Boolean(formOptions?.deliveryStatus) && (
					<Grid.Row className="req-filter-component__row">
						<Grid.Column width={16}>
							<Field
								name="deliveryStatus"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								search
								noResultsMessage="Не найдено"
								placeholder="Статус доставки"
								options={formOptions?.deliveryStatus || []}
								isWithoutSelectOnBlur
							/>
						</Grid.Column>
					</Grid.Row>
				)}
				{Boolean(formOptions?.partner) && (
					<Grid.Row className="req-filter-component__row">
						<Grid.Column width={16}>
							<Field
								name="partner"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								search
								noResultsMessage="Не найдено"
								placeholder="Контрагент"
								options={formOptions?.partner || []}
								isWithoutSelectOnBlur
							/>
						</Grid.Column>
					</Grid.Row>
				)}
				{Boolean(formOptions?.customer) && (
					<>
						<Grid.Row className="req-filter-component__row">
							<Grid.Column width={16}>
								<Field
									name="customer"
									type="text"
									component={FormSelectField}
									autoComplete="off"
									search
									noResultsMessage="Не найдено"
									placeholder="Заказчик"
									options={formOptions?.customer || []}
									isWithoutSelectOnBlur
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className="req-filter-component__row">
							<Grid.Column width={16}>
								<Field
									name="exec"
									type="text"
									component={FormSelectField}
									autoComplete="off"
									search
									noResultsMessage="Не найдено"
									placeholder="Все кроме"
									multiple
									options={formOptions?.customer || []}
									isWithoutSelectOnBlur
								/>
							</Grid.Column>
						</Grid.Row>
					</>
				)}
				{Boolean(formOptions?.carrier) && (
					<Grid.Row className="req-filter-component__row">
						<Grid.Column width={16}>
							<Field
								name="carrier"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								search
								noResultsMessage="Не найдено"
								placeholder="Перевозчик"
								options={formOptions?.carrier || []}
								isWithoutSelectOnBlur
							/>
						</Grid.Column>
					</Grid.Row>
				)}
				{Boolean(formOptions?.way) && (
					<Grid.Row className="req-filter-component__row">
						<Grid.Column width={16}>
							<Field
								name="way"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								search
								noResultsMessage="Не найдено"
								placeholder="Маршрут"
								options={formOptions?.way || []}
								isWithoutSelectOnBlur
							/>
						</Grid.Column>
					</Grid.Row>
				)}
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<hr />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row_with-label">
					<Grid.Column width={16}>
						<span className="req-filter-component__row-label">Дата отгрузки</span>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={8}>
						<Field
							name="date.from"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder="от"
							loading={false}
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="date.to"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder="до"
							loading={false}
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row_with-label">
					<Grid.Column width={16}>
						<span className="req-filter-component__row-label">Дата доставки</span>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={8}>
						<Field
							name="date_done.from"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder="от"
							loading={false}
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="date_done.to"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder="до"
							loading={false}
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16}>
						<hr />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row_with-label">
					<Grid.Column width={16}>
						<span className="req-filter-component__row-label">Масса, кг</span>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={8}>
						<Field
							name="weight.from"
							type="text"
							normalize={normalizeNumber}
							component={FormInputField}
							autoComplete="off"
							placeholder="от"
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="weight.to"
							type="text"
							normalize={normalizeNumber}
							component={FormInputField}
							autoComplete="off"
							placeholder="до"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row_with-label">
					<Grid.Column width={16}>
						<span className="req-filter-component__row-label">Объем, м³</span>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={8}>
						<Field
							name="volume.from"
							type="text"
							normalize={normalizeNumber}
							component={FormInputField}
							autoComplete="off"
							placeholder="от"
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="volume.to"
							type="text"
							normalize={normalizeNumber}
							component={FormInputField}
							autoComplete="off"
							placeholder="до"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="req-filter-component__row">
					<Grid.Column width={16} className="req-filter-component__column_with-group">
						<div className="req-filter-component__column_with-group-checkboxes">
							<div className="req-filter-component__column_with-label-checkbox">
								<Field name="my" type="checkbox" component={FormCheckboxField} />
								<span className="req-filter-component__row-label">Показывать только мои</span>
							</div>
							<div className="req-filter-component__column_with-label-checkbox">
								<Field name="relationship" type="checkbox" component={FormCheckboxField} />
								<span className="req-filter-component__row-label">Мои ТЭО</span>
							</div>
						</div>
						<div className="req-filter-component__column_with-label-button">
							<StatusButton
								button={{ name: 'Поиск', color: 'success' }}
								onClick={onSearchButtonClick}
								width="105px"
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

const ReqFilterForm = reduxForm({
	form: FILTER_FORM_NAME,
	enableReinitialize: true,
})(ReqFilterComponent);

ReqFilterComponent.propTypes = {
	onSearchButtonClick: PropTypes.func.isRequired,
	formOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			}),
		),
	),
	onKeyDown: PropTypes.func.isRequired,
};

export { ReqFilterForm };
