import {
	RESET_STATE,
	SET_TRAILER_INFO,
	SET_TRAILER_TYPES,
	SET_IS_TRAILER_DELETING,
	SET_IS_TRAILER_INFO_LOADING,
	SET_IS_TRAILER_SAVING,
	SET_IS_TRAILER_TYPES_LOADING,
	SET_IS_MODES_LOADING,
	SET_IS_TONNAGES_LOADING,
	SET_MODES,
	SET_TONNAGES,
} from './actions';

export const initialState = {
	trailerInfo: null,
	isTrailerInfoLoading: false,
	isTrailerDeleting: false,
	isTrailerSaving: false,
	isTonnagesLoading: false,
	isTrailerTypesLoading: false,
	isModesLoading: false,
	tonnages: [],
	trailerTypes: [],
	modes: [],
};

const trailersModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_TRAILER_INFO:
			return {
				...state,
				trailerInfo: payload,
			};
		case SET_IS_TRAILER_INFO_LOADING:
			return {
				...state,
				isTrailerInfoLoading: payload,
			};
		case SET_IS_TRAILER_DELETING:
			return {
				...state,
				isTrailerDeleting: payload,
			};
		case SET_IS_TRAILER_SAVING:
			return {
				...state,
				isTrailerSaving: payload,
			};
		case SET_TONNAGES:
			return {
				...state,
				tonnages: payload,
			};
		case SET_IS_TONNAGES_LOADING:
			return {
				...state,
				isTonnagesLoading: payload,
			};
		case SET_TRAILER_TYPES:
			return {
				...state,
				trailerTypes: payload,
			};
		case SET_IS_TRAILER_TYPES_LOADING:
			return {
				...state,
				isTrailerTypesLoading: payload,
			};
		case SET_MODES:
			return {
				...state,
				modes: payload,
			};
		case SET_IS_MODES_LOADING:
			return {
				...state,
				isModesLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default trailersModalReducer;
