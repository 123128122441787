import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Icon, Input } from 'semantic-ui-react';
import { ModalActionButtons } from '../../../../semantic-ui/components/modal-window/modal-action-buttons';

const ModalHeader = ({
	onKeyPress,
	searchInputValue,
	onSearchInputChange,
	onSearchInputClear,
	onAddButtonClick,
	onModalClose,
}) => {
	return (
		<div className="modal-header">
			<div className="modal-header__actions">
				{onAddButtonClick && (
					<div className="modal-header__img">
						<div className="modal-header__header-button" onClick={onAddButtonClick} />
					</div>
				)}
				<div className="modal-header__search">
					<Input
						input={{
							onKeyDown: onKeyPress,
							value: searchInputValue,
							onChange: onSearchInputChange,
						}}
						placeholder="Поиск"
						icon={searchInputValue ? <Icon name="remove" link onClick={onSearchInputClear} /> : null}
					/>
				</div>
			</div>
			<div className="modal-header__close-button">
				<ModalActionButtons buttons={[{ type: 'close', onClick: onModalClose, text: 'forgot' }]} />
			</div>
		</div>
	);
};

ModalHeader.propTypes = {
	searchInputValue: PropTypes.string.isRequired,
	onKeyPress: PropTypes.func.isRequired,
	onSearchInputChange: PropTypes.func.isRequired,
	onSearchInputClear: PropTypes.func.isRequired,
	onAddButtonClick: PropTypes.func.isRequired,
	onModalClose: PropTypes.func.isRequired,
};

export { ModalHeader };
