import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';
import { ADDRESSES_TABLE_ROWS_NAMES, ADDRESSES_FORM_NAME } from './constants';
import { getLocalStorageItem } from '../../utils/localStorage';
import { getFromStorage } from '../../components/sidebar/utils';

export const selectAddressModalsDomain = state => state.addressesModalsReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const getIsAddressInfoLoadingSelector = () =>
	createSelector(
		selectAddressModalsDomain,
		({ isAddressInfoLoading, isAddressDeleting, isAddressUpdating, isAddressCreating }) =>
			isAddressInfoLoading || isAddressDeleting || isAddressUpdating || isAddressCreating,
	);

export const getAddressInfoSelector = () =>
	createSelector(selectAddressModalsDomain, selectRouterDomain, ({ addressInfo }, { location }) => {
		return Object.entries(ADDRESSES_TABLE_ROWS_NAMES).reduce((result, [key, value]) => {
			if (key === 'link') {
				result.push({
					id: key,
					title: value,
					value: {
						url: `https://yandex.ru/maps/?pt=${addressInfo.geo_lon},${addressInfo.geo_lat}&z=18&l=map`,
						text: 'Показать',
						target: '_blank',
					},
				});
				return result;
			}

			if (key === 'contact' && addressInfo?.contact) {
				const rules = getFromStorage('rules');

				if (rules.contacts?.access) {
					const query = new URLSearchParams(location.search);
					query.set('modal', 'contact');
					query.set('contactId', addressInfo?.contact?.id);

					result.push({
						id: 'contact_link',
						title: value,
						value: {
							url: `${location.pathname}?${query}`,
							text: `${addressInfo.contact.last_name || ''} ${addressInfo.contact.first_name || ''} ${
								addressInfo.contact.middle_name || ''
							}`,
						},
					});
				} else {
					result.push({
						id: 'contact',
						title: value,
						value: `${addressInfo.contact.last_name || ''} ${addressInfo.contact.first_name || ''} ${
							addressInfo.contact.middle_name || ''
						}`,
					});
				}

				return result;
			}

			if (key === 'contact' && !addressInfo?.contact) {
				result.push({
					id: 'contact',
					title: value,
					value: '---',
				});
				return result;
			}

			if (key === 'author') {
				result.push({
					id: key,
					title: value,
					value: {
						name: `${addressInfo?.user?.last_name || ''} ${addressInfo?.user?.first_name || ''}`,
						role: addressInfo?.user?.role?.name || '',
					},
				});
				return result;
			}

			result.push({ id: key, title: value, value: addressInfo[key] ? String(addressInfo[key]) : '---' });

			return result;
		}, []);
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectAddressModalsDomain, getFormValues(ADDRESSES_FORM_NAME), ({ addressInfo }, formValues) => {
		const company = getLocalStorageItem('user_data')?.company;

		return {
			...addressInfo,
			...formValues,
			...(!formValues && !addressInfo.name && company ? { name: company } : {}),
		};
	});

export const getAddressNameSelector = () =>
	createSelector(selectAddressModalsDomain, ({ addressInfo }) => addressInfo?.name || '-');

export const getAddressSelector = () =>
	createSelector(selectAddressModalsDomain, ({ addressInfo }) => addressInfo?.address || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && (query?.modal === 'address' || query?.modal === 'contacts'));
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'address' && query?.tab === 'audit');
	});

export const getActiveAddressIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query, pathname } }) => {
		if (pathname.includes('partners')) {
			return query?.modal === 'address' ? query?.addressId : null;
		}

		return query?.modal === 'address' ? query?.id : null;
	});

export const getActivePartnerIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query, pathname } }) => {
		if (pathname.includes('partners') && query?.modal === 'address') {
			return query?.id;
		}

		return null;
	});

export const getIsAddressAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'address' && query?.mode === 'add');
	});

export const getIsAddressEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'address' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getIsPartnersRouteSelector = () =>
	createSelector(selectRouterDomain, ({ location: { pathname } }) => pathname.includes('partners'));

export const getDataForSaveSelector = () =>
	createSelector(
		selectAddressModalsDomain,
		getFormValues(ADDRESSES_FORM_NAME),
		({ addressesOptions }, formValues) => {
			const currentAddress = addressesOptions.find(({ value }) => value === formValues?.address);

			return { ...(currentAddress?.data || {}), ...formValues };
		},
	);

export const getDateForUpdateSelector = () =>
	createSelector(selectAddressModalsDomain, getFormValues(ADDRESSES_FORM_NAME), ({ addressInfo }, formValues) => ({
		...addressInfo,
		...formValues,
	}));

export const getAddressSearchValueSelector = () =>
	createSelector(selectAddressModalsDomain, ({ addressSearchValue }) => addressSearchValue);

export const getIsAddressesOptionsLoadingSelector = () =>
	createSelector(selectAddressModalsDomain, ({ isAddressesOptionsLoading }) => isAddressesOptionsLoading);

export const getAddressesSelector = () =>
	createSelector(selectAddressModalsDomain, ({ addressesOptions }) => addressesOptions);

export const getAddressesOptionsSelector = () =>
	createSelector(selectAddressModalsDomain, ({ addressesOptions }) => {
		return addressesOptions.map(({ value }) => ({ key: value, text: value, value }));
	});

export const getFormIsInvalidSelector = () => createSelector(isInvalid(ADDRESSES_FORM_NAME), isInvalid => isInvalid);

export const getContactsSearchValueSelector = () =>
	createSelector(selectAddressModalsDomain, ({ contactsSearchValue }) => contactsSearchValue);

export const getContactsSelector = () => createSelector(selectAddressModalsDomain, ({ contacts }) => contacts);

export const getAddressValueSelector = () =>
	createSelector(selectAddressModalsDomain, ({ addressInfo }) => addressInfo?.address);
