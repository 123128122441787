export const ACCEPT_TERMS = 'accept-terms/ACCEPT_TERMS';
export const acceptTermsAction = payload => ({
	type: ACCEPT_TERMS,
	payload,
});

export const SET_IS_FORM_SENDING = 'accept-terms/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
