export const ORGANIZATION_DOCUMENTS = {
	POLICY: 'https://docs.mak-logic.ru/personal_data_policy.pdf',
	AGREEMENT: 'https://docs.mak-logic.ru/personal_data_approval.pdf',
	NOTIFICATION: 'https://docs.mak-logic.ru/notification_cookie.pdf',
	CONDITIONS: 'https://docs.mak-logic.ru/conditions.pdf',
};

export const FORM_NAME = 'accept-terms-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
