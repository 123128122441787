import CaseIcon from '../images/case-icon.svg';
import ProfileIcon from '../images/profile-icon.svg';
import FolderIcon from '../images/folder-add-icon.svg';
import HumansIcon from '../images/humans-icon.svg';
import DictionariesIcon from '../images/dictionaries-icon.svg';
import UserIcon from '../images/user-icon.svg';
import UsersIcon from '../images/users-icon.svg';

export const MENU_ICONS = {
	home: ProfileIcon,
	reqs: CaseIcon,
	documents: FolderIcon,
	employees: HumansIcon,
	dictionaries: DictionariesIcon,
	users: UserIcon,
	users_roles: UsersIcon,
	relationship: UsersIcon,
};
