import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectDocumentAttachmentModalDomain = state => state.documentAttachmentModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'document_attachment_form');
	});

export const getDocumentsSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ documents }) => {
		return documents;
	});

export const getFilesSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ files }) => {
		return files;
	});

export const getDataForSaveSelector = () =>
	createSelector(
		selectDocumentAttachmentModalDomain,
		getFormValues(FORM_NAME),
		({ files, documents }, formValues) => {
			const formData = new FormData();

			Object.entries(files || {}).forEach(([key, value]) => {
				const currentDoc = documents.find(doc => doc.key === key);
				value.forEach(file => {
					formData.append(currentDoc?.is_multiple ? `${key}[]` : key, file.file, file.name);
				});
			});
			if (formValues?.document_name) {
				formData.append('document_name', formValues.document_name);
			}

			return formData;
		},
	);

export const getFormErrorsSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ formErrors }) => {
		return formErrors;
	});

export const getIsFormLoadingSelector = () =>
	createSelector(selectDocumentAttachmentModalDomain, ({ isDocumentsLoading, isFormSending }) => {
		return isDocumentsLoading || isFormSending;
	});
