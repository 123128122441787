/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import saga from './saga';
import reducer from './reducer';
import { getCompaniesTypesAction, getCompanyInfoAction, resetStateAction, updateCompanyAction } from './actions';
import {
	getActiveCompanyIdSelector,
	getCompanyInfoSelector,
	getCompanyNameSelector,
	getIsAuditTabShownSelector,
	getIsCompanyEditFormOpenSelector,
	getIsCompanyInfoLoadingSelector,
	getIsModalShownSelector,
	getIsProfileCompanyFormSelector,
} from './selectors';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import { CompanyContent } from './components/company-content';
import { ModalWindow } from '../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { removeModalQuery } from '../../../utils/removeModalQuery';
import { getIsAvailableCompaniesButtonsSelector } from '../../profile/selectors';
import { Audit } from '../../../components/audit';

const CompaniesModalWrapper = ({
	onGetCompanyInfo,
	isCompanyInfoLoading,
	isCompanyEditFormOpen,
	companyInfo,
	companyName,
	onResetState,
	isModalShown,
	activeCompanyId,
	onUpdateCompany,
	onGetCompaniesTypes,
	isAvailable,
	isAuditTabShown,
	isProfileCompanyForm,
}) => {
	useInjectSaga({ key: 'companiesModalSaga', saga });
	useInjectReducer({ key: 'companiesModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		return () => onResetState();
	}, []);

	useEffect(() => {
		if (activeCompanyId) onGetCompanyInfo(activeCompanyId);
	}, [activeCompanyId]);

	useEffect(() => {
		if (isCompanyEditFormOpen) onGetCompaniesTypes();
	}, [isCompanyEditFormOpen]);

	const handleCompanyFormOpenButtonClick = mode => {
		if (activeCompanyId) {
			query.set('id', activeCompanyId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeCompanyId) {
			onResetState();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleCompanyFormSubmit = () => {
		if (activeCompanyId) {
			onUpdateCompany({
				id: activeCompanyId,
				redirect: () => handleCompanyFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
		}
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleCompanyFormSubmit();
		}
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={isCompanyEditFormOpen ? 'Редактор компании' : companyName}
			navItems={
				isCompanyEditFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<CompanyContent
						companyInfo={companyInfo}
						isCompanyInfoLoading={isCompanyInfoLoading}
						isCompanyFormOpen={isCompanyEditFormOpen}
					/>
				) : (
					<Audit entityName="companies" entityId={activeCompanyId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'company-modal' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={
						isCompanyEditFormOpen && !isAuditTabShown
							? [
									{
										onClick: handleCompanyFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isCompanyInfoLoading,
									},
									{
										onClick: () => handleCompanyFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isCompanyInfoLoading,
									},
							  ]
							: [
									...((isAvailable.update || isProfileCompanyForm) && !isAuditTabShown
										? [
												{
													onClick: () => handleCompanyFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isCompanyInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	companyInfo: getCompanyInfoSelector(),
	isCompanyInfoLoading: getIsCompanyInfoLoadingSelector(),
	isCompanyEditFormOpen: getIsCompanyEditFormOpenSelector(),
	companyName: getCompanyNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeCompanyId: getActiveCompanyIdSelector(),
	isAvailable: getIsAvailableCompaniesButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
	isProfileCompanyForm: getIsProfileCompanyFormSelector(),
});

const mapDispatchToProps = {
	onGetCompanyInfo: getCompanyInfoAction,
	onResetState: resetStateAction,
	onUpdateCompany: updateCompanyAction,
	onGetCompaniesTypes: getCompaniesTypesAction,
};

const CompaniesModal = connect(mapStateToProps, mapDispatchToProps)(CompaniesModalWrapper);

CompaniesModalWrapper.propTypes = {
	onGetCompanyInfo: PropTypes.func.isRequired,
	companyInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isCompanyInfoLoading: PropTypes.bool.isRequired,
	isCompanyEditFormOpen: PropTypes.bool.isRequired,
	companyName: PropTypes.string.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeCompanyId: PropTypes.string,
	onUpdateCompany: PropTypes.func.isRequired,
	onGetCompaniesTypes: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	isAuditTabShown: PropTypes.bool.isRequired,
	isProfileCompanyForm: PropTypes.bool.isRequired,
};

export default CompaniesModal;
