import React from 'react';
import PropTypes from 'prop-types';
import { CompanyForm } from '../company-form';
import { SimpleTableComponent } from '../../../../../components/simple-table';
import { TABLE_COLUMNS_NAMES } from '../../constants';

export const CompanyContent = ({ companyInfo, isCompanyInfoLoading, isCompanyFormOpen }) => {
	return isCompanyFormOpen ? (
		<CompanyForm />
	) : (
		<SimpleTableComponent
			rows={companyInfo}
			isTableDataLoading={isCompanyInfoLoading}
			columns={TABLE_COLUMNS_NAMES}
		/>
	);
};

CompanyContent.propTypes = {
	companyInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isCompanyInfoLoading: PropTypes.bool.isRequired,
	isCompanyFormOpen: PropTypes.bool.isRequired,
};
