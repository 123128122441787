import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { ChosenCommentFormView } from './chosen-comment-form-view';
import { FORM_NAME } from '../../constants';
import { getFormInitialValuesSelector, getIsChosenCommentLoadingSelector } from '../../selectors';

const ChosenCommentFormWrapper = ({ isChosenCommentLoading }) => {
	return <ChosenCommentFormView isLoading={isChosenCommentLoading} />;
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(ChosenCommentFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isChosenCommentLoading: getIsChosenCommentLoadingSelector(),
});

const ChosenCommentForm = connect(mapStateToProps)(withForm);

ChosenCommentFormWrapper.propTypes = {
	isChosenCommentLoading: PropTypes.bool.isRequired,
};

export { ChosenCommentForm };
