/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { REQUEST_FORM_NAME } from '../../constants';
import {
	getAddressesOptionsSelector,
	getTypesSelector,
	getContactsOptionsSelector,
	getModesSelector,
	getPartnersOptionsSelector,
	getIsPrrSelector,
	getRequestFormInitialValuesSelector,
	getAddressSearchValueSelector,
	getContactSearchValueSelector,
	getContactsSelector,
	getPartnerSearchValueSelector,
	getFeaturesSelector,
	getIsValidationCheckboxDisabledSelector,
	getIsAvailablePartnersSelector,
	getIsAvailableAddressesSelector,
	getIsAvailableContactsSelector,
	getTenderTypesSelector,
	getTenderCompaniesSelector,
	getIsMyCarrierSelectedSelector,
} from '../../selectors';
import {
	getContactsAction,
	getAddressesAction,
	getPartnersWithDebounceAction,
	setActivePointIndexAction,
	setRequestInfoAction,
	getAddressWithDebounceAction,
	setAddressSearchValueAction,
	getContactWithDebounceAction,
	setContactSearchValueAction,
	setContactsAction,
	setPartnerSearchValueAction,
} from '../../actions';
import { validateRequestForm } from '../../utils';
import { RequestFormView } from './request-form-view-express';

const RequestFormWrapper = ({
	onGetAddresses,
	partners,
	addresses,
	onGetContacts,
	contacts,
	types,
	modes,
	isPrr,
	onGetPartnersWithDebounce,
	onSetActivePointIndex,
	RequestInfo,
	onSetRequestInfo,
	onGetAddressWithDebounce,
	onSetAddressSearchValue,
	addressSearchValue,
	contactSearchValue,
	onGetContactWithDebounce,
	onSetContactSearchValue,
	onSetContacts,
	contactsOptions,
	partnerSearchValue,
	onSetPartnerSearchValue,
	features,
	isValidationCheckboxDisabled,
	onFieldChange,
	isAvailablePartners,
	isAvailableAddresses,
	isAvailableContacts,
	tenderTypes,
	tenderCompanies,
	isMyCarrierSelected,
}) => {
	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const handlePartnerSearchChange = ({ searchQuery }, index) => {
		onSetPartnerSearchValue({ ...partnerSearchValue, [index]: searchQuery });
		onGetPartnersWithDebounce(index);
	};

	const handlePartnerChange = (value, index) => {
		const currentPartner = partners[index].find(partner => partner.id === value);

		if (currentPartner) {
			const newPoints = [...(RequestInfo.points || [])];

			newPoints[index] = {
				...(RequestInfo.points?.[index] || {}),
				partner: {
					id: currentPartner.id,
					inn: currentPartner.inn,
					name: currentPartner.name,
					ogrn: currentPartner.ogrn,
				},
			};

			delete newPoints[index].address;
			delete newPoints[index].contact;

			// change form to remove address and contact fields from form
			onFieldChange(REQUEST_FORM_NAME, 'points', newPoints);

			if (partnerSearchValue[index]) {
				onSetPartnerSearchValue({ ...partnerSearchValue, [index]: '' });
				onGetPartnersWithDebounce(index);
			}

			if (contactSearchValue[index]) {
				onSetContactSearchValue({ ...contactSearchValue, [index]: '' });
			}

			if (addressSearchValue[index]) {
				onSetAddressSearchValue({ ...addressSearchValue, [index]: '' });
			}

			onSetRequestInfo({ ...RequestInfo, points: newPoints });
		}

		onGetAddresses({ id: value, index });
		onSetContacts({ ...contactsOptions, [index]: [] });
	};

	const handleAddressSearchChange = ({ searchQuery }, index) => {
		const id = RequestInfo?.points?.[index]?.partner?.id;

		if (!id) return;

		onSetAddressSearchValue({ ...addressSearchValue, [index]: searchQuery });
		onGetAddressWithDebounce({ id, index });
	};

	const handleAddressChange = (value, index) => {
		const currentAddress = addresses[index].find(address => address.id === value);

		if (currentAddress) {
			const newPoints = [...(RequestInfo.points || [])];

			newPoints[index] = {
				...(RequestInfo.points?.[index] || {}),
				address: {
					id: currentAddress.id,
					address: currentAddress.address,
					city_type_full: currentAddress.city_type_full,
					city_type: currentAddress.city_type,
					city: currentAddress.city,
					city_with_type: currentAddress.city_with_type,
					city_kladr_id: currentAddress.city_kladr_id,
					city_fias_id: currentAddress.city_fias_id,
					street_type_full: currentAddress.street_type_full,
					street_type: currentAddress.street_type,
					street: currentAddress.street,
					street_with_type: currentAddress.street_with_type,
					street_kladr_id: currentAddress.street_kladr_id,
					street_fias_id: currentAddress.street_fias_id,
					settlement_type_full: currentAddress.settlement_type_full,
					settlement_type: currentAddress.settlement_type,
					settlement: currentAddress.settlement,
					settlement_with_type: currentAddress.settlement_with_type,
					settlement_kladr_id: currentAddress.settlement_kladr_id,
					settlement_fias_id: currentAddress.settlement_fias_id,
					house_type_full: currentAddress.house_type_full,
					house_type: currentAddress.house_type,
					house: currentAddress.house,
					house_kladr_id: currentAddress.house_kladr_id,
					house_fias_id: currentAddress.house_fias_id,
					block_type_full: currentAddress.block_type_full,
					block_type: currentAddress.block_type,
					block: currentAddress.block,
				},
			};

			delete newPoints[index].contact;

			if (contactSearchValue[index]) {
				onSetContactSearchValue({ ...contactSearchValue, [index]: '' });
			}

			if (addressSearchValue[index]) {
				onSetAddressSearchValue({ ...addressSearchValue, [index]: '' });
				onGetAddressWithDebounce({ id: RequestInfo?.points?.[index]?.partner?.id, index });
			}

			onSetRequestInfo({ ...RequestInfo, points: newPoints });
		}

		onGetContacts({ id: value, index });
	};

	const handleContactSearchChange = ({ searchQuery }, index) => {
		const id = RequestInfo?.points?.[index]?.address?.id;

		if (!id) return;

		onSetContactSearchValue({ ...contactSearchValue, [index]: searchQuery });
		onGetContactWithDebounce({ id, index, notChangeDefault: true });
	};

	const handleContactChange = (value, index) => {
		const currentContact = contacts[index].find(contact => contact.id === value);

		if (currentContact) {
			const newPoints = [...(RequestInfo.points || [])];

			newPoints[index] = {
				...(RequestInfo.points?.[index] || {}),
				contact: {
					id: currentContact.id,
					email: currentContact.email,
					extension: currentContact.extension,
					first_name: currentContact.first_name,
					is_default: currentContact.is_default,
					last_name: currentContact.last_name,
					middle_name: currentContact.middle_name,
					phone: currentContact.phone,
				},
			};

			if (contactSearchValue[index]) {
				onSetContactSearchValue({ ...contactSearchValue, [index]: '' });
				onGetContactWithDebounce({
					id: RequestInfo?.points?.[index]?.address?.id,
					index,
					notChangeDefault: true,
				});
			}

			onSetRequestInfo({ ...RequestInfo, points: newPoints });
		}
	};

	const handlePointChange = (value, index, field) => {
		const newPoints = [...(RequestInfo.points || [])];

		newPoints[index] = {
			...(RequestInfo.points?.[index] || {}),
			[field]: value,
		};

		onSetRequestInfo({ ...RequestInfo, points: newPoints });
	};

	const handleAddPartnerButtonClick = index => {
		query.set('modal', 'request-partner');

		onSetActivePointIndex(index);
		onSetRequestInfo(RequestInfo);
		history.push(`${pathname}?${query}`);
	};

	const handleAddAddressButtonClick = index => {
		if (!RequestInfo.points?.[index]?.partner) {
			return;
		}

		query.set('modal', 'request-address');

		onSetActivePointIndex(index);
		onSetRequestInfo(RequestInfo);
		history.push(`${pathname}?${query}`);
	};

	const handleAddContactButtonClick = index => {
		if (!RequestInfo.points?.[index]?.address) {
			return;
		}

		query.set('modal', 'request-contact');

		onSetActivePointIndex(index);
		onSetRequestInfo(RequestInfo);
		history.push(`${pathname}?${query}`);
	};

	const handleTypeChange = value => {
		if (value === 1) {
			onFieldChange(REQUEST_FORM_NAME, 'is_thermo_check', true);
			onFieldChange(REQUEST_FORM_NAME, 'is_validated', false);
		}

		if (value === 2) {
			onFieldChange(REQUEST_FORM_NAME, 'is_thermo_check', false);
			onFieldChange(REQUEST_FORM_NAME, 'is_validated', false);
		}

		if (value === 3) {
			onFieldChange(REQUEST_FORM_NAME, 'is_thermo_check', true);
			onFieldChange(REQUEST_FORM_NAME, 'is_validated', true);
		}
	};

	return (
		<RequestFormView
			partners={partners}
			addresses={addresses}
			contacts={contacts}
			onPartnerChange={handlePartnerChange}
			onAddressChange={handleAddressChange}
			onContactChange={handleContactChange}
			onPartnerSearchChange={handlePartnerSearchChange}
			onAddPartnerButtonClick={handleAddPartnerButtonClick}
			onAddAddressButtonClick={handleAddAddressButtonClick}
			onAddContactButtonClick={handleAddContactButtonClick}
			onPointChange={handlePointChange}
			onAddressSearchChange={handleAddressSearchChange}
			onContactSearchChange={handleContactSearchChange}
			types={types}
			modes={modes}
			features={features}
			tenderTypes={tenderTypes}
			tenderCompanies={tenderCompanies}
			isPrr={isPrr}
			partnerSearchValue={partnerSearchValue}
			addressSearchValue={addressSearchValue}
			contactSearchValue={contactSearchValue}
			isValidationCheckboxDisabled={isValidationCheckboxDisabled}
			onTypeChange={handleTypeChange}
			isAvailablePartners={isAvailablePartners}
			isAvailableAddresses={isAvailableAddresses}
			isAvailableContacts={isAvailableContacts}
			isMyCarrierSelected={isMyCarrierSelected}
		/>
	);
};

const withForm = reduxForm({
	form: REQUEST_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validateRequestForm,
})(RequestFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getRequestFormInitialValuesSelector(),
	RequestInfo: getRequestFormInitialValuesSelector(),
	partners: getPartnersOptionsSelector(),
	addresses: getAddressesOptionsSelector(),
	contacts: getContactsOptionsSelector(),
	modes: getModesSelector(),
	types: getTypesSelector(),
	features: getFeaturesSelector(),
	tenderTypes: getTenderTypesSelector(),
	tenderCompanies: getTenderCompaniesSelector(),
	isPrr: getIsPrrSelector(),
	partnerSearchValue: getPartnerSearchValueSelector(),
	addressSearchValue: getAddressSearchValueSelector(),
	contactSearchValue: getContactSearchValueSelector(),
	contactsOptions: getContactsSelector(),
	isValidationCheckboxDisabled: getIsValidationCheckboxDisabledSelector(),
	isAvailablePartners: getIsAvailablePartnersSelector(),
	isAvailableAddresses: getIsAvailableAddressesSelector(),
	isAvailableContacts: getIsAvailableContactsSelector(),
	isMyCarrierSelected: getIsMyCarrierSelectedSelector(),
});

const mapDispatchToProps = {
	onGetAddresses: getAddressesAction,
	onGetContacts: getContactsAction,
	onSetPartnerSearchValue: setPartnerSearchValueAction,
	onGetPartnersWithDebounce: getPartnersWithDebounceAction,
	onSetActivePointIndex: setActivePointIndexAction,
	onSetRequestInfo: setRequestInfoAction,
	onGetAddressWithDebounce: getAddressWithDebounceAction,
	onSetAddressSearchValue: setAddressSearchValueAction,
	onGetContactWithDebounce: getContactWithDebounceAction,
	onSetContactSearchValue: setContactSearchValueAction,
	onSetContacts: setContactsAction,
	onFieldChange: change,
};

const RequestFormExpress = connect(mapStateToProps, mapDispatchToProps)(withForm);

RequestFormWrapper.propTypes = {
	isValidationCheckboxDisabled: PropTypes.bool.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	onGetAddresses: PropTypes.func.isRequired,
	partners: PropTypes.object.isRequired,
	addresses: PropTypes.object.isRequired,
	contacts: PropTypes.object.isRequired,
	modes: PropTypes.array.isRequired,
	types: PropTypes.array.isRequired,
	features: PropTypes.array.isRequired,
	tenderTypes: PropTypes.array.isRequired,
	tenderCompanies: PropTypes.array.isRequired,
	onGetContacts: PropTypes.func.isRequired,
	isPrr: PropTypes.shape({ isPrr: PropTypes.bool, points: PropTypes.arrayOf(PropTypes.bool) }).isRequired,
	onGetPartnersWithDebounce: PropTypes.func.isRequired,
	onSetPartnerSearchValue: PropTypes.func.isRequired,
	onSetActivePointIndex: PropTypes.func.isRequired,
	RequestInfo: PropTypes.object.isRequired,
	onSetRequestInfo: PropTypes.func.isRequired,
	onGetAddressWithDebounce: PropTypes.func.isRequired,
	onSetAddressSearchValue: PropTypes.func.isRequired,
	onGetContactWithDebounce: PropTypes.func.isRequired,
	onSetContactSearchValue: PropTypes.func.isRequired,
	onSetContacts: PropTypes.func.isRequired,
	contactsOptions: PropTypes.object.isRequired,
	partnerSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	addressSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	contactSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	isAvailablePartners: PropTypes.bool.isRequired,
	isAvailableAddresses: PropTypes.bool.isRequired,
	isAvailableContacts: PropTypes.bool.isRequired,
	isMyCarrierSelected: PropTypes.bool.isRequired,
};

export { RequestFormExpress };
