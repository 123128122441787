export const GET_NOTE = 'note/GET_NOTE';
export const getNoteAction = payload => ({
	type: GET_NOTE,
	payload,
});

export const SET_NOTE = 'note/SET_NOTE';
export const setNoteAction = payload => ({
	type: SET_NOTE,
	payload,
});

export const SET_IS_NOTE_LOADING = 'note/SET_IS_NOTE_LOADING';
export const setIsNoteLoadingAction = payload => ({
	type: SET_IS_NOTE_LOADING,
	payload,
});

export const SEND_NOTE = 'note/SEND_NOTE';
export const sendNoteAction = payload => ({
	type: SEND_NOTE,
	payload,
});

export const SET_IS_FORM_SENDING = 'note/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
