/* eslint-disable no-mixed-spaces-and-tabs */
import { SubmissionError } from 'redux-form';

export const valueToLink = (key, value) => {
	if (key === 'email') {
		return `mailto:${value}`;
	}

	return `tel:${value}`;
};

export const normalizePhone = value => {
	if (!value) {
		return value;
	}

	if (value === '+') {
		return '';
	}

	const onlyNums = value.replace(/[^\d]/g, '');

	if (onlyNums[0] !== '7' && onlyNums.length === 1) {
		return `+7${onlyNums}`;
	}

	if (onlyNums.length <= 4) {
		return `+7${onlyNums.slice(1, 4)}`;
	}

	if (onlyNums.length <= 7) {
		return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4)}`;
	}

	if (onlyNums.length <= 9) {
		return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4, 7)}-${onlyNums.slice(7)}`;
	}

	return `+7(${onlyNums.slice(1, 4)})${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 9)}-${onlyNums.slice(9, 11)}`;
};

export const validate = values => {
	if (!values.post) {
		throw new SubmissionError({ post: 'Это поле обязательно для заполнения' });
	}
	if (!values.last_name) {
		throw new SubmissionError({ last_name: 'Это поле обязательно для заполнения' });
	}
	if (!values.first_name) {
		throw new SubmissionError({ first_name: 'Это поле обязательно для заполнения' });
	}
	if (!values.email) {
		throw new SubmissionError({ email: 'Это поле обязательно для заполнения' });
	}
	if (!values.phone) {
		throw new SubmissionError({ phone: 'Это поле обязательно для заполнения' });
	}
};

export const transformToValidationErrors = errors => {
	return Object.entries(errors).reduce((result, [key, value]) => {
		return {
			...result,
			[key]: value[0],
		};
	}, {});
};

export const getFormattedRules = (rules, key) => {
	return rules?.[key]
		? {
				create: Boolean(rules?.[key].access) && Boolean(rules?.[key].create),
				update: Boolean(rules?.[key].access) && Boolean(rules?.[key].update),
				delete: Boolean(rules?.[key].access) && Boolean(rules?.[key].delete),
		  }
		: { create: false, update: false, delete: false };
};

export const checkEquality = (property, userInfo, formValues) => {
	let value = Boolean(userInfo?.[property]);

	if (
		Boolean(userInfo?.[property]) !== formValues?.[property] &&
		formValues?.[property] !== undefined &&
		formValues?.[property] !== null
	) {
		value = formValues?.[property];
	}

	return value;
};
