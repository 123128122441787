/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reset, submit } from 'redux-form';
import { getNoteAction, sendNoteAction, setNoteAction } from './actions';
import { getIsModalShownSelector, getIsFormSendingSelector } from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { NoteForm } from './components/note-form';

const NoteModalWrapper = ({
	onGetNote,
	isModalShown,
	onSendNote,
	onResetForm,
	isFormSending,
	onSetNote,
	onFormSubmit,
}) => {
	useInjectSaga({ key: 'noteModalSaga', saga });
	useInjectReducer({ key: 'noteModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetNote(query.get('id'));
		}
	}, [isModalShown]);

	const handleModalClose = () => {
		query.set('modal', 'reqs_details');
		onResetForm(FORM_NAME);
		onSetNote(null);

		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);
		onSendNote({
			redirect: handleModalClose,
			id: query.get('id'),
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText="Заметка"
			modalContent={<NoteForm />}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'note' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleFormSubmit,
							text: 'Сохранить',
							color: 'success',
							loading: isFormSending,
							disabled: isFormSending,
						},
						{
							onClick: handleModalClose,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	isFormSending: getIsFormSendingSelector(),
});

const mapDispatchToProps = {
	onGetNote: getNoteAction,
	onSendNote: sendNoteAction,
	onResetForm: reset,
	onSetNote: setNoteAction,
	onFormSubmit: submit,
};

const NoteModal = connect(mapStateToProps, mapDispatchToProps)(NoteModalWrapper);

NoteModalWrapper.propTypes = {
	onGetNote: PropTypes.func.isRequired,
	onSendNote: PropTypes.func.isRequired,
	isFormSending: PropTypes.bool.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onResetForm: PropTypes.func.isRequired,
	onSetNote: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default NoteModal;
