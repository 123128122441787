/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import {
	changeCarrierAction,
	getCarriersAction,
	getRequestInfoAction,
	rejectsRequestAction,
	resetStateAction,
} from './actions';
import {
	getCarriersInfoSelector,
	getIsCarriersLoadingSelector,
	getIsModalShownSelector,
	getIsRefusalButtonVisibleSelector,
	getModalStatusesSelector,
	getReqAdditionalInfoSelector,
	getReqNameSelector,
} from './selectors';
import { TABLE_COLUMNS_NAMES } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { TableComponent } from '../../components/table-component';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';

const CarrierChangeModalWrapper = ({
	onResetState,
	isModalShown,
	carriersInfo,
	isCarriersLoading,
	onGetRequestInfo,
	reqName,
	reqAdditionalInfo,
	modalStatuses,
	onRejectsRequest,
	onGetCarriers,
	isRefusalButtonVisible,
}) => {
	useInjectSaga({ key: 'carrierChangeModalSaga', saga });
	useInjectReducer({ key: 'carrierChangeModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetRequestInfo(query.get('id'));
			onGetCarriers(query.get('id'));
		}
	}, [isModalShown]);

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('id');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleRefusalButtonClick = () => {
		onRejectsRequest({ redirect: handleModalClose, id: query.get('id'), searchQuery: query.toString() });
	};

	const handleCustomCellButtonClick = id => {
		query.set('carrier-id', id);
		query.set('modal', 'carrier-approval');
		history.push(`${pathname}?${query}`);
	};

	const refusalButton = [
		{
			onClick: handleRefusalButtonClick,
			text: 'Отказать всем',
			color: 'warning',
			disabled: isCarriersLoading,
		},
	];

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText={reqName}
			headerAdditionalText={reqAdditionalInfo}
			modalContent={
				<div>
					<TableComponent
						headerText="Предложения"
						columns={TABLE_COLUMNS_NAMES}
						rows={carriersInfo}
						isTableDataLoading={isCarriersLoading}
						isWithoutHeader
						isWithoutFooter
						onCustomCellButtonClick={handleCustomCellButtonClick}
					/>
				</div>
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'carrier_change' }]}
			headerStatuses={modalStatuses}
			footerButtons={
				<ModalFooterButtons
					leftButtons={carriersInfo.length && isRefusalButtonVisible ? refusalButton : []}
					rightButtons={[
						{
							onClick: handleModalClose,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	carriersInfo: getCarriersInfoSelector(),
	isCarriersLoading: getIsCarriersLoadingSelector(),
	reqName: getReqNameSelector(),
	reqAdditionalInfo: getReqAdditionalInfoSelector(),
	modalStatuses: getModalStatusesSelector(),
	isRefusalButtonVisible: getIsRefusalButtonVisibleSelector(),
});

const mapDispatchToProps = {
	onResetState: resetStateAction,
	onGetRequestInfo: getRequestInfoAction,
	onChangeCarrier: changeCarrierAction,
	onRejectsRequest: rejectsRequestAction,
	onGetCarriers: getCarriersAction,
};

const CarrierChangeModal = connect(mapStateToProps, mapDispatchToProps)(CarrierChangeModalWrapper);

CarrierChangeModalWrapper.propTypes = {
	isRefusalButtonVisible: PropTypes.bool.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	isCarriersLoading: PropTypes.bool.isRequired,
	carriersInfo: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			price: PropTypes.string.isRequired,
			company: PropTypes.shape({ name: PropTypes.string, inn: PropTypes.string }),
		}),
	).isRequired,
	onGetRequestInfo: PropTypes.func.isRequired,
	reqName: PropTypes.string.isRequired,
	reqAdditionalInfo: PropTypes.string.isRequired,
	onRejectsRequest: PropTypes.func.isRequired,
	onGetCarriers: PropTypes.func.isRequired,
	modalStatuses: PropTypes.arrayOf(PropTypes.shape({ color: PropTypes.string, name: PropTypes.string })).isRequired,
};

export default CarrierChangeModal;
