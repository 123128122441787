import { createSelector } from 'reselect';
import { REQS_PAGE_LIST } from './constants';
import { getCountSelector } from '../reqs-list/selectors';

export const getTabsSelector = () =>
	createSelector(getCountSelector(), count => {
		if (count) {
			return REQS_PAGE_LIST.map(tab => {
				const urlId = tab.href.split('/')[2];
				if (count[urlId]?.[tab.tab]) {
					tab.count = count[urlId]?.[tab.tab];
				} else {
					tab.count = null;
				}

				return tab;
			});
		}

		return REQS_PAGE_LIST;
	});
