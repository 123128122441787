/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getActiveTrailerIdSelector,
	getTrailerInfoSelector,
	getTrailerNameSelector,
	getFormInitialValuesSelector,
	getIsAuditTabShownSelector,
	getIsTrailerAddFormOpenSelector,
	getIsTrailerEditFormOpenSelector,
	getIsTrailerInfoLoadingSelector,
	getIsModalShownSelector,
} from './selectors';
import {
	createTrailerAction,
	deleteTrailerAction,
	getTrailerInfoAction,
	getTrailerTypesAction,
	getModesAction,
	getTonnagesAction,
	resetStateAction,
	updateTrailerAction,
} from './actions';
import { TrailerContent } from './components/trailer-content';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableTrailersButtonsSelector } from '../profile/selectors';
import { validate } from './utils';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const TrailersModalWrapper = ({
	onGetTrailerInfo,
	isTrailerInfoLoading,
	isTrailerEditFormOpen,
	isTrailerAddFormOpen,
	trailerInfo,
	trailerName,
	onResetState,
	onDeleteTrailer,
	isModalShown,
	activeTrailerId,
	onCreateTrailer,
	onUpdateTrailer,
	onClearForm,
	isAvailable,
	onFormSubmit,
	onSetWindowInfo,
	isAuditTabShown,
	onGetTonnages,
	onGetTrailerTypes,
	onGetModes,
}) => {
	useInjectSaga({ key: 'trailersModalSaga', saga });
	useInjectReducer({ key: 'trailersModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetTonnages();
			onGetTrailerTypes();
			onGetModes();
		}
	}, [isModalShown]);

	useEffect(() => {
		if (activeTrailerId) onGetTrailerInfo(activeTrailerId);
	}, [activeTrailerId]);

	const handleTrailerFormOpenButtonClick = mode => {
		if (activeTrailerId) {
			query.set('id', activeTrailerId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			query.delete('mode');
			onClearForm();
		}

		if (mode === 'cancel' && !activeTrailerId) {
			onResetState();
			onClearForm();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		onClearForm();
		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleTrailerDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteTrailer({ id: activeTrailerId, redirect: handleModalClose }),
			},
		});
	};

	const handleTrailerFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeTrailerId) {
			onUpdateTrailer({
				id: activeTrailerId,
				redirect: () => handleTrailerFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onCreateTrailer({
			redirect: handleModalClose,
			resetState: onResetState,
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleTrailerFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isTrailerEditFormOpen || isTrailerAddFormOpen
					? `${isTrailerEditFormOpen ? 'Редактор прицепа' : 'Новая прицеп'}`
					: trailerName
			}
			navItems={
				isTrailerAddFormOpen || isTrailerEditFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<TrailerContent
						trailerInfo={trailerInfo}
						isTrailerInfoLoading={isTrailerInfoLoading}
						isTrailerFormOpen={isTrailerEditFormOpen || isTrailerAddFormOpen}
					/>
				) : (
					<Audit entityName="trailers" entityId={activeTrailerId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'trailer' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isTrailerEditFormOpen || isTrailerAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handleTrailerDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isTrailerInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isTrailerEditFormOpen || isTrailerAddFormOpen
							? [
									{
										onClick: handleTrailerFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isTrailerInfoLoading,
									},
									{
										onClick: () => handleTrailerFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isTrailerInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleTrailerFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isTrailerInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(TrailersModalWrapper);

const mapStateToProps = createStructuredSelector({
	trailerInfo: getTrailerInfoSelector(),
	isTrailerInfoLoading: getIsTrailerInfoLoadingSelector(),
	isTrailerAddFormOpen: getIsTrailerAddFormOpenSelector(),
	isTrailerEditFormOpen: getIsTrailerEditFormOpenSelector(),
	trailerName: getTrailerNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeTrailerId: getActiveTrailerIdSelector(),
	initialValues: getFormInitialValuesSelector(),
	isAvailable: getIsAvailableTrailersButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetTrailerInfo: getTrailerInfoAction,
	onDeleteTrailer: deleteTrailerAction,
	onResetState: resetStateAction,
	onCreateTrailer: createTrailerAction,
	onUpdateTrailer: updateTrailerAction,
	onClearForm: () => reset(FORM_NAME),
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
	onGetTonnages: getTonnagesAction,
	onGetTrailerTypes: getTrailerTypesAction,
	onGetModes: getModesAction,
};

const TrailersModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

TrailersModalWrapper.propTypes = {
	onGetTrailerInfo: PropTypes.func.isRequired,
	trailerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTrailerEditFormOpen: PropTypes.bool.isRequired,
	isTrailerAddFormOpen: PropTypes.bool.isRequired,
	trailerName: PropTypes.string.isRequired,
	onDeleteTrailer: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeTrailerId: PropTypes.string,
	onCreateTrailer: PropTypes.func.isRequired,
	onUpdateTrailer: PropTypes.func.isRequired,
	onClearForm: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onFormSubmit: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	onGetTonnages: PropTypes.func.isRequired,
	onGetTrailerTypes: PropTypes.func.isRequired,
	onGetModes: PropTypes.func.isRequired,
};

export default TrailersModal;
