import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { change, getFormValues, reset } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import { ReqsFilterView } from './components/reqs-filter-view';
import {
	getAccordionIndexSelector,
	getAnalyticsAccordionIndexSelector,
	getAnalyticsSelector,
	getFilterOptionsSelector,
	getIsAnalyticsClearButtonViewSelector,
	getIsFilterClearButtonViewSelector,
} from './selectors';
import { setAccordionIndexAction, setAnalyticsAccordionIndexAction, setAnalyticsAction } from './actions';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { getUserCompanyTypeSelector } from '../right-header-content/selectors';
import { ANALYTICS_FORM_NAME, FILTER_FORM_FIELDS, FILTER_FORM_NAME } from './constants';
import { flattenObject, scrollToTop } from './utils';
import { getReqsWithFiltersAction } from '../reqs-list/actions';

export const ReqsFilterWrapper = ({
	accordionIndex,
	onSetAccordionIndex,
	userCompanyType,
	formOptions,
	filterFormValues,
	isFilterClearButtonView,
	onFilterFormReset,
	onFormChange,
	onSetAnalytics,
	analytics,
	isAnalyticsClearButtonView,
	analyticsAccordionIndex,
	onSetAnalyticsAccordionIndex,
	onGetReqsWithFilters,
}) => {
	useInjectReducer({ key: 'reqsFilterReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (analytics.length > 0) {
			onSetAccordionIndex(1);
		}
	}, [analytics.length]);

	useEffect(() => {
		onFilterFormReset(FILTER_FORM_NAME);
		onFilterFormReset(ANALYTICS_FORM_NAME);
		onSetAccordionIndex(0);
		onSetAnalyticsAccordionIndex([]);
	}, [window.location.pathname]);

	useEffect(() => {
		FILTER_FORM_FIELDS.forEach(field => {
			if (field === 'region.to' && query.has(`filter.${field}[]`)) {
				onFormChange(FILTER_FORM_NAME, field, query.getAll(`filter.${field}[]`));
			}

			if (field === 'exec' && query.has(`filter.${field}[]`)) {
				onFormChange(FILTER_FORM_NAME, field, query.getAll(`filter.${field}[]`));
			}

			if (field === 'mode' && query.has(`filter.${field}[]`)) {
				const modes = query.getAll(`filter.${field}[]`).map(mode => {
					if (mode === 'null') {
						return 'none';
					}

					return mode;
				});

				onFormChange(FILTER_FORM_NAME, field, modes);
			}

			if (field === 'search' && query.has(`${field}`)) {
				onFormChange(FILTER_FORM_NAME, field, query.get(`${field}`));
			}

			if (query.has(`filter.${field}`)) {
				onFormChange(FILTER_FORM_NAME, field, query.get(`filter.${field}`));
			}
		});
	}, []);

	const handleAccordionClick = (_, item) => {
		const { index } = item;

		const newIndex = accordionIndex === index ? -1 : index;

		onSetAccordionIndex(newIndex);
	};

	const handleAnalyticsAccordionClick = (event, item) => {
		if (event.target.nodeName === 'LABEL') {
			return;
		}
		if (item) {
			const { index } = item;

			let newIndex = [];

			if (analyticsAccordionIndex.includes(index)) {
				newIndex = analyticsAccordionIndex.filter(el => el !== index);
			} else {
				newIndex = [...analyticsAccordionIndex, index];
			}

			onSetAnalyticsAccordionIndex(newIndex);
		}
	};

	const handleSearchButtonClick = () => {
		const filters = flattenObject(filterFormValues);

		query.delete('filter.region.to[]');
		query.delete('filter.exec[]');
		query.delete('filter.mode[]');
		query.delete('search');
		query.set('page', 1);

		FILTER_FORM_FIELDS.forEach(field => {
			query.delete(`filter.${field}`);
		});

		Object.entries(filters).forEach(([key, value]) => {
			if (key === 'filter.region.to' || key === 'filter.exec' || key === 'filter.mode') {
				const cities = value.split(',');

				cities.forEach(city => {
					query.append(`${key}[]`, city === 'none' ? null : city);
				});
			} else if (key === 'filter.my') {
				if (value) {
					query.set(key, 'on');
				}
			} else if (key === 'filter.relationship') {
				if (value) {
					query.set(key, 'on');
				}
			} else if (key === 'filter.search') {
				query.set('search', value);
			} else {
				query.set(key, value);
			}
		});

		history.push(`${pathname}?${query}`);

		onGetReqsWithFilters({ id: window.location.pathname, searchQuery: query.toString(), scrollToTop });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleSearchButtonClick();
		}
	};

	const handleAnalyticsFieldChange = value => {
		onFormChange(FILTER_FORM_NAME, 'region.from', value);
	};

	const handleFilterClearButtonClick = e => {
		e.stopPropagation();
		onFilterFormReset(FILTER_FORM_NAME);
		onFilterFormReset(ANALYTICS_FORM_NAME);
		onSetAccordionIndex(0);
		onSetAnalytics([]);
		onSetAnalyticsAccordionIndex([]);

		query.delete('filter.cities[]');
		query.delete('filter.region.to[]');
		query.delete('filter.exec[]');
		query.delete('filter.mode[]');
		query.delete('filter.relationship');
		query.delete('search');
		query.set('page', 1);

		FILTER_FORM_FIELDS.forEach(field => {
			query.delete(`filter.${field}`);
		});

		history.push(`${pathname}?${query}`);

		onGetReqsWithFilters({ id: window.location.pathname, searchQuery: query.toString(), scrollToTop });
	};

	const handleAnalyticsClearButtonClick = e => {
		e.stopPropagation();

		const newAnalytics = analytics.map(el => {
			const newCities = el.cities.map(city => {
				return { ...city, isChecked: false };
			});

			return { ...el, isChecked: false, cities: newCities };
		});

		onSetAnalytics(newAnalytics);
		onSetAnalyticsAccordionIndex([]);

		query.delete('filter.cities[]');
		query.set('page', 1);
		history.push(`${pathname}?${query}`);

		onGetReqsWithFilters({ id: window.location.pathname, searchQuery: query.toString(), scrollToTop });
	};

	const handleCityCheckboxChange = item => {
		query.set('page', 1);

		if (query.has('filter.cities[]', item.id)) {
			query.delete('filter.cities[]', item.id);

			if (item.cities?.length) {
				item.cities.forEach(city => {
					if (query.has('filter.cities[]', city.id)) {
						query.delete('filter.cities[]', city.id);
					}
				});
			}
		} else {
			query.append('filter.cities[]', item.id);

			if (item.cities?.length) {
				item.cities.forEach(city => {
					if (!query.has('filter.cities[]', city.id)) {
						query.append('filter.cities[]', city.id);
					}
				});
			}
		}

		const cities = query.getAll('filter.cities[]');

		const newAnalytics = analytics.map(el => {
			const newCities = el.cities.map(city => {
				return { ...city, isChecked: cities.includes(city.id) };
			});

			const isSomeCitiesChecked = newCities.some(city => city.isChecked);

			if (isSomeCitiesChecked && !query.has('filter.cities[]', el.id)) {
				query.append('filter.cities[]', el.id);
			}

			if (!isSomeCitiesChecked && query.has('filter.cities[]', el.id)) {
				query.delete('filter.cities[]', el.id);
			}

			return { ...el, isChecked: isSomeCitiesChecked, cities: newCities };
		});

		onSetAnalytics(newAnalytics);

		history.push(`${pathname}?${query}`);

		onGetReqsWithFilters({ id: window.location.pathname, searchQuery: query.toString(), scrollToTop });
	};

	return (
		<ReqsFilterView
			accordionIndex={accordionIndex}
			onAccordionOpen={handleAccordionClick}
			onSearchButtonClick={handleSearchButtonClick}
			onSearchAnalyticsButtonClick={handleSearchButtonClick}
			userCompanyType={userCompanyType}
			formOptions={formOptions}
			isFilterClearButtonView={isFilterClearButtonView}
			onFilterClearButtonClick={handleFilterClearButtonClick}
			onAnalyticsFieldChange={handleAnalyticsFieldChange}
			analytics={analytics}
			isAnalyticsClearButtonView={isAnalyticsClearButtonView}
			onAnalyticsClearButtonClick={handleAnalyticsClearButtonClick}
			analyticsAccordionIndex={analyticsAccordionIndex}
			onAnalyticsAccordionOpen={handleAnalyticsAccordionClick}
			onCityCheckboxChange={handleCityCheckboxChange}
			onKeyDown={handleKeyDown}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	accordionIndex: getAccordionIndexSelector(),
	userCompanyType: getUserCompanyTypeSelector(),
	formOptions: getFilterOptionsSelector(),
	filterFormValues: getFormValues(FILTER_FORM_NAME),
	isFilterClearButtonView: getIsFilterClearButtonViewSelector(),
	analytics: getAnalyticsSelector(),
	isAnalyticsClearButtonView: getIsAnalyticsClearButtonViewSelector(),
	analyticsAccordionIndex: getAnalyticsAccordionIndexSelector(),
});

const mapDispatchToProps = {
	onSetAccordionIndex: setAccordionIndexAction,
	onFilterFormReset: reset,
	onFormChange: change,
	onSetAnalytics: setAnalyticsAction,
	onSetAnalyticsAccordionIndex: setAnalyticsAccordionIndexAction,
	onGetReqsWithFilters: getReqsWithFiltersAction,
};

const ReqsFilterComponent = connect(mapStateToProps, mapDispatchToProps)(ReqsFilterWrapper);

ReqsFilterWrapper.propTypes = {
	accordionIndex: PropTypes.number.isRequired,
	onSetAccordionIndex: PropTypes.func.isRequired,
	userCompanyType: PropTypes.string.isRequired,
	formOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			}),
		),
	),
	filterFormValues: PropTypes.object,
	isFilterClearButtonView: PropTypes.bool.isRequired,
	onFilterFormReset: PropTypes.func.isRequired,
	onFormChange: PropTypes.func.isRequired,
	onSetAnalytics: PropTypes.func.isRequired,
	analytics: PropTypes.arrayOf(
		PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array])),
	),
	isAnalyticsClearButtonView: PropTypes.bool.isRequired,
	onSetAnalyticsAccordionIndex: PropTypes.func.isRequired,
	analyticsAccordionIndex: PropTypes.arrayOf(PropTypes.number).isRequired,
	onGetReqsWithFilters: PropTypes.func.isRequired,
};

export default ReqsFilterComponent;
