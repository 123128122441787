/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit, change } from 'redux-form';
import { changeCarAction, getCarsAction, getRequestInfoAction, resetStateAction } from './actions';
import {
	getCarsOptionsSelector,
	getIsAddCarModalShownSelector,
	getIsAddTrailerModalShownSelector,
	getIsAllowedTrailerSelector,
	getIsButtonsDisabledSelector,
	getIsCarsLoadingSelector,
	getIsModalShownSelector,
	getModalStatusesSelector,
	getReqNameSelector,
	getTrailersOptionsSelector,
} from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { CarChangeModalForm } from './components/car-change-modal-form';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { validate } from './utils';
import { CarModal } from './components/car-form';
import { TrailerModal } from './components/trailer-form';

const CarChangeModalWrapper = ({
	onResetState,
	isModalShown,
	onChangeCar,
	onGetRequestInfo,
	reqName,
	modalStatuses,
	onResetForm,
	isButtonsDisabled,
	isCarsLoading,
	carsOptions,
	onFormSubmit,
	onGetCars,
	isAddCarModalShown,
	isAddTrailerModalShown,
	trailersOptions,
	onFiledChange,
	isAllowedTrailer,
}) => {
	useInjectSaga({ key: 'carChangeModalSaga', saga });
	useInjectReducer({ key: 'carChangeModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (isModalShown) {
			onGetRequestInfo(query.get('id'));
			onGetCars(query.get('id'));
		}
	}, [isModalShown]);

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('id');

		onResetState();
		onResetForm(FORM_NAME);
		history.push(`${pathname}?${query}`);
	};

	const handleFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		onChangeCar({ redirect: handleModalClose, id: query.get('id'), searchQuery: query.toString() });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleFormSubmit();
		}
	};

	const handleCarChange = carId => {
		const trailer = carsOptions.find(car => car.value === carId)?.trailer_id;

		onFiledChange(FORM_NAME, 'trailer_id', trailer ?? 'none');
	};

	if (isAddCarModalShown) {
		return <CarModal />;
	}

	if (isAddTrailerModalShown) {
		return <TrailerModal />;
	}

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={reqName}
			modalContent={
				<CarChangeModalForm
					isCarsLoading={isCarsLoading}
					carsOptions={carsOptions}
					trailersOptions={trailersOptions}
					onCarChange={handleCarChange}
					isAllowedTrailer={isAllowedTrailer}
				/>
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'car_change' }]}
			headerStatuses={modalStatuses}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleFormSubmit,
							text: 'Назначить',
							color: 'primary',
							disabled: isButtonsDisabled,
						},
						{
							onClick: handleModalClose,
							text: 'Отмена',
							color: 'secondary',
						},
					]}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(CarChangeModalWrapper);

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	reqName: getReqNameSelector(),
	modalStatuses: getModalStatusesSelector(),
	isButtonsDisabled: getIsButtonsDisabledSelector(),
	isCarsLoading: getIsCarsLoadingSelector(),
	carsOptions: getCarsOptionsSelector(),
	isAddCarModalShown: getIsAddCarModalShownSelector(),
	isAddTrailerModalShown: getIsAddTrailerModalShownSelector(),
	trailersOptions: getTrailersOptionsSelector(),
	isAllowedTrailer: getIsAllowedTrailerSelector(),
});

const mapDispatchToProps = {
	onResetState: resetStateAction,
	onChangeCar: changeCarAction,
	onGetRequestInfo: getRequestInfoAction,
	onResetForm: reset,
	onGetCars: getCarsAction,
	onFormSubmit: submit,
	onFiledChange: change,
};

const CarChangeModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

CarChangeModalWrapper.propTypes = {
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onChangeCar: PropTypes.func.isRequired,
	onGetRequestInfo: PropTypes.func.isRequired,
	reqName: PropTypes.string.isRequired,
	onResetForm: PropTypes.func.isRequired,
	isButtonsDisabled: PropTypes.bool.isRequired,
	isCarsLoading: PropTypes.bool.isRequired,
	carsOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
			trailer_id: PropTypes.number,
		}),
	).isRequired,
	trailersOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	modalStatuses: PropTypes.arrayOf(PropTypes.shape({ color: PropTypes.string, name: PropTypes.string })).isRequired,
	onGetCars: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	isAddCarModalShown: PropTypes.bool.isRequired,
	isAddTrailerModalShown: PropTypes.bool.isRequired,
	onFiledChange: PropTypes.func.isRequired,
	isAllowedTrailer: PropTypes.bool.isRequired,
};

export default CarChangeModal;
