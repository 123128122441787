import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { FORM_NAME } from './constants';

export const getIsButtonEnabledSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues =>
		Boolean(formValues?.is_accepted_personal_data_docs && formValues?.is_accepted_conditions_docs),
	);

export const getDataForSaveSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);
