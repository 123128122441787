import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Pagination, Select } from 'semantic-ui-react';

const TablePagination = ({
	pagination: {
		currentPage,
		totalPages,
		total,
		from,
		to,
		isNextItem,
		isPrevItem,
		onPageChange,
		isLastItem,
		isFirstItem,
		isPaginationTextHidden,
		onSelectChange,
		perPage,
	},
}) => {
	return (
		<div className={`table-pagination${totalPages !== 1 ? ' table-pagination_full' : ''}`}>
			{totalPages !== 1 ? (
				<div className="table-pagination__selector">
					<span className="table-pagination__selector-label">Показывать</span>
					<span className="table-pagination__selector-field">
						<Select
							onChange={onSelectChange}
							options={[
								{ text: '100', value: '100' },
								{ text: '50', value: '50' },
								{ text: '30', value: '30' },
								{ text: '10', value: '10' },
							]}
							value={String(perPage)}
						/>
					</span>
				</div>
			) : null}
			{!isPaginationTextHidden && (
				<div className="table-pagination__text ">
					Показано с <b>{from}</b> по <b>{to}</b> из <b>{total}</b> результатов
				</div>
			)}
			<div className="table-pagination__pagination">
				{totalPages !== 1 ? (
					<Pagination
						nextItem={{
							className: `table-pagination__next-button table-pagination__next-button${
								currentPage === totalPages ? '_inactive' : '_active'
							}`,
							disabled: !isNextItem,
							content: '›',
						}}
						prevItem={{
							'aria-label': 'Previous item',
							content: '‹',
							className: `table-pagination__prev-button table-pagination__prev-button${
								currentPage === 1 ? '_inactive' : '_active'
							}`,
							disabled: !isPrevItem,
						}}
						firstItem={isFirstItem}
						lastItem={isLastItem}
						totalPages={totalPages}
						activePage={currentPage}
						onPageChange={onPageChange}
					/>
				) : null}
			</div>
		</div>
	);
};

TablePagination.propTypes = {
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
		onPageChange: PropTypes.func.isRequired,
		onSelectChange: PropTypes.func.isRequired,
		isPaginationTextHidden: PropTypes.bool,
		perPage: PropTypes.number.isRequired,
	}).isRequired,
};
export default TablePagination;
