export const GET_RELATIONSHIP_LIST = 'relationship/GET_RELATIONSHIP_LIST';
export const getRelationshipListAction = payload => ({
	type: GET_RELATIONSHIP_LIST,
	payload,
});

export const SET_RELATIONSHIP_LIST = 'relationship/SET_RELATIONSHIP_LIST';
export const setRelationshipListAction = payload => ({
	type: SET_RELATIONSHIP_LIST,
	payload,
});

export const SET_PAGINATION = 'relationship/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_IS_RELATIONSHIP_LIST_LOADING = 'relationship/SET_IS_RELATIONSHIP_LIST_LOADING';
export const setIsRelationshipListLoadingAction = payload => ({
	type: SET_IS_RELATIONSHIP_LIST_LOADING,
	payload,
});
export const SET_TABLE_SEARCH = 'relationship/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'relationship/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
