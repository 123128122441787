import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { NoteFormView } from './note-form-view';
import { FORM_NAME } from '../../constants';
import { validate } from '../../utils';
import { getFormInitialValuesSelector, getIsNoteLoadingSelector } from '../../selectors';

const NoteFormWrapper = ({ isNoteLoading }) => {
	return <NoteFormView isLoading={isNoteLoading} />;
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(NoteFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	isNoteLoading: getIsNoteLoadingSelector(),
});

const NoteForm = connect(mapStateToProps)(withForm);

NoteFormWrapper.propTypes = {
	isNoteLoading: PropTypes.bool.isRequired,
};

export { NoteForm };
