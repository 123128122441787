/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AlertModalView } from './alert-modal-view';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { getIsWindowOpenSelector, getWindowInfoSelector } from './selectors';
import { logout } from '../../utils/logout';
import reducer from './reducer';
import { useInjectReducer } from '../../utils/injectReducer';
import { setWindowInfoAction } from './actions';

const AlertModalWrapper = ({ isWindowOpen, windowInfo, onSetWindowInfo }) => {
	useInjectReducer({ key: 'alertModalReducer', reducer });

	const handleLogOutButtonClick = () => {
		onSetWindowInfo(null);
		logout();
	};

	const handleWindowClose = () => {
		onSetWindowInfo(null);
	};

	const buttons = useMemo(() => {
		if (windowInfo?.type === 'logout') {
			return [{ type: 'success', text: 'Ок', onClick: handleLogOutButtonClick }];
		}

		if (windowInfo?.type === 'forbidden') {
			return [{ type: 'success', text: 'Ок', onClick: handleWindowClose }];
		}

		if (windowInfo?.type === 'confirm') {
			return [{ type: 'success', text: 'Ок', onClick: handleWindowClose }];
		}

		if (windowInfo?.type === 'error') {
			return [
				{ type: 'success', text: 'Обновить страницу', onClick: () => window.location.reload() },
				{ type: 'error', text: 'Отмена', onClick: handleWindowClose },
			];
		}

		if (windowInfo?.type === 'delete') {
			return [
				{
					...windowInfo?.button,
					onClick: () => {
						windowInfo?.button?.onClick();
						handleWindowClose();
					},
				},
				{ type: 'error', text: 'Нет', onClick: handleWindowClose },
			];
		}

		return windowInfo?.buttons || [];
	}, [windowInfo?.type]);

	if (!windowInfo) return null;

	return (
		<ModalWindow
			isModalShown={isWindowOpen}
			modalContent={
				<AlertModalView
					title={windowInfo.title}
					text={windowInfo.text}
					description={windowInfo.description}
					phone={windowInfo.phone}
					email={windowInfo.email}
					buttons={buttons}
					type={windowInfo.type === 'logout' ? 'error' : 'warning'}
				/>
			}
			style={{ width: '450px' }}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isWindowOpen: getIsWindowOpenSelector(),
	windowInfo: getWindowInfoSelector(),
});

const mapDispatchToProps = {
	onSetWindowInfo: setWindowInfoAction,
};

AlertModalWrapper.propTypes = {
	isWindowOpen: PropTypes.bool.isRequired,
	windowInfo: PropTypes.object,
	onSetWindowInfo: PropTypes.func.isRequired,
};

const AlertModal = connect(mapStateToProps, mapDispatchToProps)(AlertModalWrapper);

export { AlertModal };
