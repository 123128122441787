import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';

const CustomCarCell = ({ rowData }) => {
	if (rowData.id.includes('mode')) {
		return (
			<span className="custom-car-cell__mode">
				<span>
					<b>Валидация: </b>
					{rowData.mode.validate}
				</span>
				<span>
					<b>Действительна до: </b>
					{rowData.mode.date}
				</span>
			</span>
		);
	}

	return null;
};

CustomCarCell.propTypes = {
	rowData: PropTypes.object.isRequired,
};

export { CustomCarCell };
