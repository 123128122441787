export const OPTIONS_KEYS_VALUES = {
	customer: 'id',
	carrier: 'id',
	from: 'region_kladr_id',
	to: 'region_kladr_id',
	mode: 'id',
	type: 'id',
	partner: 'id',
	status: 'id',
	way: 'id',
	deliveryStatus: 'id',
};

export const OPTIONS_KEYS_NAMES = {
	customer: 'name',
	carrier: 'name',
	from: 'region_with_type',
	to: 'region_with_type',
	mode: 'name',
	type: 'name',
	partner: 'name',
	status: 'name',
	way: 'name',
	deliveryStatus: 'name',
};

export const FILTER_FORM_NAME = 'filter-form';
export const ANALYTICS_FORM_NAME = 'analytics-form';

export const FILTER_FORM_FIELDS = [
	'region.from',
	'region.to',
	'date.from',
	'date.to',
	'date_done.from',
	'date_done.to',
	'mode',
	'type',
	'weight.from',
	'weight.to',
	'volume.from',
	'volume.to',
	'status',
	'partner',
	'customer',
	'carrier',
	'exec',
	'my',
	'relationship',
	'way',
	'search',
	'deliveryStatus',
];
