export const REQUEST_FORM_NAME = 'request-form';

export const ADD_PARTNER_FORM_NAME = 'add-partner-form';

export const ADD_ADDRESS_FORM_NAME = 'add-address-form';

export const ADD_CONTACT_FORM_NAME = 'add-contact-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const PARTNER_FORM_REQUIRED_FIELDS = ['name', 'inn', 'ogrn'];

export const ADDRESS_FORM_REQUIRED_FIELDS = ['name', 'address', 'work_mode'];

export const CONTACT_FORM_REQUIRED_FIELDS = ['last_name', 'first_name', 'email', 'phone'];

export const AVIA_REQUEST_FORM_REQUIRED_FIELDS = [
	'tender_type_id',
	'points.0',
	'rate',
	'type',
	'mode',
	'cost',
	'size',
	'points.1',
	'options_points',
];

export const AVIA_REQUEST_FORM_REQUIRED_FIELDS_NOT_PLACES = [
	'tender_type_id',
	'points.0',
	'rate',
	'type',
	'mode',
	'cost',
	'weight',
	'volume',
	'place',
	'points.1',
	'options_points',
];

export const REQUEST_FORM_REQUIRED_FIELDS = [
	'tender_type_id',
	'points.0',
	'type',
	'mode',
	'cost',
	'weight',
	'volume',
	'points.1',
	'options_points',
];

export const AVIA_REQUEST_FORM_FIELDS_NAMES = [
	'tender_type_id',
	'tender_tk_id',
	'tender_amount',
	'points.0',
	'rate',
	'product_invoice',
	'type',
	'cost',
	'size',
	'points.1',
	'options_points',
	'time_from',
	'time_to',
];

export const REQUEST_FORM_FIELDS_NAMES = [
	'tender_type_id',
	'tender_tk_id',
	'tender_amount',
	'points.0',
	'type',
	'cost',
	'weight',
	'volume',
	'points.1',
	'options_points',
	'product_invoice',
	'time_from',
	'time_to',
];

export const SIZE_REQUIRED_FIELDS = ['place', 'weight', 'length', 'width', 'height'];

export const FROM_POINT_REQUIRED_FIELDS = ['partner', 'address', 'date', 'contact', 'time_from', 'time_to'];

export const POINT_REQUIRED_FIELDS = [...FROM_POINT_REQUIRED_FIELDS, 'weight', 'volume'];

export const AVIA_POINT_REQUIRED_FIELDS = [...FROM_POINT_REQUIRED_FIELDS, 'size'];

export const MY_CARRIER_REQUIRED_FIELDS = ['tender_type_id', 'tender_tk_id', 'tender_amount'];

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const MY_CARRIER_ID = 3;

export const PLATFORM_ID = 1;
