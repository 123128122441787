import { SET_IS_FORM_SENDING, SET_IS_CHOSEN_COMMENT_LOADING, SET_CHOSEN_COMMENT } from './actions';

export const initialState = {
	chosenComment: null,
	isChosenCommentLoading: false,
	isFormSending: false,
};

const chosenCommentModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_CHOSEN_COMMENT:
			return {
				...state,
				chosenComment: payload,
			};
		case SET_IS_CHOSEN_COMMENT_LOADING:
			return {
				...state,
				isChosenCommentLoading: payload,
			};

		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		default:
			return state;
	}
};

export default chosenCommentModalReducer;
