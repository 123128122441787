import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.less';
import ru from 'date-fns/locale/ru';
import { Loader } from 'semantic-ui-react';
import { Header } from './header';

const CustomDatePicker = ({
	input = {},
	meta: { error, touched } = {},
	placeholder,
	dateFormat,
	disabled,
	loading,
	textPosition,
	isWithoutErrorText,
	showMonthYearPicker,
}) => {
	const [month, seatMonth] = useState(null);

	const selectedDay = input?.value;

	const handleDateChange = date => {
		input?.onChange?.(date ? moment(date).format('YYYY-MM-DD') : '');
		if (date) {
			seatMonth(moment(date).format('YYYY-MM'));
		}
	};

	const handlePickerClose = () => {
		seatMonth(null);
	};

	const handleCalendarOpen = () => {
		if (selectedDay) {
			seatMonth(moment(selectedDay).format('YYYY-MM'));
		}
	};

	return (
		<div className="custom-datepicker">
			{loading && <Loader active size="tiny" />}
			<DatePicker
				className={`${error ? 'custom-datepicker__input_error ' : ''}${
					textPosition ? `custom-datepicker__input_text_${textPosition} ` : ''
				}custom-datepicker__input`}
				calendarClassName="custom-datepicker__calendar"
				dayClassName={date => {
					const currentMonth = month ? moment(month).get('month') : moment().get('month');

					const dateMonth = moment(date).get('month');

					if (selectedDay && moment(date).format('YYYY-MM-DD') === moment(selectedDay).format('YYYY-MM-DD')) {
						return 'custom-datepicker__day custom-datepicker__day_active';
					}

					if (currentMonth !== dateMonth) {
						return 'custom-datepicker__day custom-datepicker__day_gray';
					}

					return 'custom-datepicker__day';
				}}
				selected={selectedDay && new Date(selectedDay)}
				onChange={handleDateChange}
				onCalendarClose={handlePickerClose}
				onCalendarOpen={handleCalendarOpen}
				// eslint-disable-next-line react/jsx-boolean-value
				closeOnScroll={true}
				shouldCloseOnSelect={false}
				locale={ru}
				placeholderText={placeholder}
				dateFormat={dateFormat}
				disabled={disabled}
				popperPlacement="top-start"
				showMonthYearPicker={showMonthYearPicker}
				popperModifiers={[
					{
						name: 'preventOverflow',
						options: {
							rootBoundary: 'viewport',
							tether: false,
							altAxis: true,
						},
					},
				]}
				renderCustomHeader={({ decreaseMonth, increaseMonth, monthDate }) => {
					return (
						<Header
							decreaseMonth={decreaseMonth}
							increaseMonth={increaseMonth}
							monthDate={monthDate}
							seatMonth={seatMonth}
						/>
					);
				}}
			/>
			{error && touched && !isWithoutErrorText && <span className="custom-datepicker__error-text">{error}</span>}
		</div>
	);
};

CustomDatePicker.propTypes = {
	input: PropTypes.shape({
		value: PropTypes.string,
		onChange: PropTypes.func,
	}),
	meta: PropTypes.shape({
		error: PropTypes.string,
		touched: PropTypes.bool,
	}),
	placeholder: PropTypes.string,
	dateFormat: PropTypes.arrayOf(PropTypes.string),
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	textPosition: PropTypes.oneOf(['left', 'center', 'right', '']),
	isWithoutErrorText: PropTypes.bool,
	showMonthYearPicker: PropTypes.bool,
};

export { CustomDatePicker };
