/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectNoteModalDomain = state => state.noteModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getIsNoteLoadingSelector = () =>
	createSelector(selectNoteModalDomain, ({ isNoteLoading }) => isNoteLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectNoteModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'note');
	});

export const getDataForRequestSelector = () => createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getFormInitialValuesSelector = () =>
	createSelector(selectNoteModalDomain, getFormValues(FORM_NAME), ({ note, isNoteLoading }, formValues) => {
		if (isNoteLoading) {
			return {};
		}

		return {
			note: formValues?.note ?? note?.note,
		};
	});
