import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const Timeline = ({ timeline }) => {
	return (
		<div className="timeline">
			{timeline.map(item => (
				<div className="timeline__item" key={item.names[0]}>
					<div
						className={`timeline__side timeline__side--left${
							item.color === 'gray' ? ' timeline__side--gray' : ''
						}${item.color === 'primary' ? ' timeline__side--primary' : ''}${
							item.current ? ' timeline__side--current' : ''
						}`}
					>
						{item.names[0].name}
					</div>
					<div className="timeline__marker">
						<div
							className={`timeline__circle${
								item.color === 'primary' ? ' timeline__circle--primary' : ''
							}`}
						/>
						<div className="timeline__line" />
					</div>
					<div
						className={`timeline__side timeline__side--right timeline__side--${
							item.color
						} timeline__side--${item.current ? 'current' : ''}`}
					>
						{item.names[1].name}
					</div>
				</div>
			))}
		</div>
	);
};

Timeline.propTypes = {
	timeline: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string.isRequired,
			current: PropTypes.bool.isRequired,
			uid: PropTypes.string.isRequired,
			names: PropTypes.arrayOf(
				PropTypes.shape({ name: PropTypes.string.isRequired, company_type: PropTypes.string.isRequired }),
			).isRequired,
		}),
	).isRequired,
};

export { Timeline };
