/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import './style.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { normalizePhone } from '../../../utils';
import { FormSelect } from '../../../../../semantic-ui/components/form-select';
import { FormDoublePhoneField } from '../../../../../semantic-ui/components/form-double-phone-field';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { ORGANIZATION_DOCUMENTS } from '../../../constants';

export const RegistrationFormView = ({
	companiesOptions,
	isCompaniesLoading,
	onCompanySearchChange,
	onCompanyChange,
	companiesSearchValue,
}) => {
	return (
		<div className="registration-form">
			<section className="registration-form__section">
				<header className="registration-form__section-header">
					<h3 className="registration-form__section-title">Компания</h3>
					<span className="registration-form__header-text">
						Вы можете заполнить реквизиты в ручную или выбрать из справочника
					</span>
				</header>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right registration-form__section-text_bold">
						Справочник
					</span>
					<div className="registration-form__section-field">
						<Field
							name="select"
							type="text"
							component={FormSelect}
							autoComplete="off"
							placeholder="Введите ИНН/ОГРН или название организации"
							icon="search"
							options={companiesOptions}
							loading={isCompaniesLoading}
							noResultsMessage="Неизвестная организация"
							onSearchChange={onCompanySearchChange}
							onItemChange={onCompanyChange}
							searchValue={companiesSearchValue}
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Название организации
					</span>
					<div className="registration-form__section-field">
						<Field
							name="company.name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите название организации"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">ИНН</span>
					<div className="registration-form__section-field">
						<Field
							name="company.inn"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите ИНН"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">ОГРН</span>
					<div className="registration-form__section-field">
						<Field
							name="company.ogrn"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите ОГРН"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Юридический адрес организации
					</span>
					<div className="registration-form__section-field">
						<Field
							name="company.address"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите юридический адрес организации"
						/>
					</div>
				</div>
			</section>
			<section className="registration-form__section">
				<header className="registration-form__section-header">
					<h3 className="registration-form__section-title">Контактное лицо</h3>
					<span className="registration-form__header-text registration-form__header-text_block">
						Укажите данные контактного лица компании,
					</span>
					<span className="registration-form__header-text">
						с кем мы сможем связываться по вопросам регистрации, аккредитации, доставки и т.д
					</span>
				</header>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Фамилия
					</span>
					<div className="registration-form__section-field">
						<Field
							name="contact.last_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите фамилию"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">Имя</span>
					<div className="registration-form__section-field">
						<Field
							name="contact.first_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите имя"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Отчество
					</span>
					<div className="registration-form__section-field">
						<Field
							name="contact.middle_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите отчество"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">Почта</span>
					<div className="registration-form__section-field">
						<Field
							name="contact.email"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите почту"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Телефон
					</span>
					<div className="registration-form__section-phone-field">
						<FormDoublePhoneField
							mainFieldName="contact.phone"
							additionalFieldName="contact.extension"
							mainPlaceholder="Укажите телефон"
							additionalPlaceholder="Добавочный"
							mainNormalize={normalizePhone}
						/>
					</div>
				</div>
			</section>
			<section className="registration-form__section">
				<header className="registration-form__section-header">
					<h3 className="registration-form__section-title">Пользователь</h3>
					<span className="registration-form__header-text">
						Укажите все данные пользователя\администратора аккаунта
					</span>
				</header>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Должность
					</span>
					<div className="registration-form__section-field">
						<Field
							name="user.post"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите должность"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Фамилия
					</span>
					<div className="registration-form__section-field">
						<Field
							name="user.last_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите фамилию"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">Имя</span>
					<div className="registration-form__section-field">
						<Field
							name="user.first_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите имя"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Отчество
					</span>
					<div className="registration-form__section-field">
						<Field
							name="user.middle_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите отчество"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">Почта</span>
					<div className="registration-form__section-field">
						<Field
							name="user.email"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите почту"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Телефон
					</span>
					<div className="registration-form__section-phone-field">
						<FormDoublePhoneField
							mainFieldName="user.phone"
							additionalFieldName="user.extension"
							mainPlaceholder="Укажите телефон"
							additionalPlaceholder="Добавочный"
							mainNormalize={normalizePhone}
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Пароль
					</span>
					<div className="registration-form__section-field">
						<Field
							name="user.password"
							type="password"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите пароль"
						/>
					</div>
				</div>
				<div className="registration-form__section-row">
					<span className="registration-form__section-text registration-form__section-text_right">
						Подтверждение пароля
					</span>
					<div className="registration-form__section-field">
						<Field
							name="user.password_confirmation"
							type="password"
							component={FormInputField}
							autoComplete="off"
							placeholder="Подтвердите пароль"
						/>
					</div>
				</div>
				<div className="registration-form__section-row registration-form__section-row_bottom">
					<div className="registration-form__section-text registration-form__section-text_right">
						<Field
							name="user.is_accepted_personal_data_docs"
							type="checkbox"
							component={FormCheckboxField}
						/>
					</div>
					<span className="registration-form__section-field registration-form__section-text-documents">
						Я ознакомлен(а) с{' '}
						<a href={ORGANIZATION_DOCUMENTS.POLICY} target="_blank" rel="noopener noreferrer">
							Политикой
						</a>{' '}
						в отношении
						<br />
						персональных данных,{' '}
						<a href={ORGANIZATION_DOCUMENTS.AGREEMENT} target="_blank" rel="noopener noreferrer">
							Соглашением
						</a>{' '}
						<br /> о конфиденциальности и даю свое{' '}
						<span className="registration-form__section-text-documents registration-form__section-text-documents_accent">
							согласие
						</span>{' '}
						<br /> на обработку персональных данных.
					</span>
				</div>
				<div className="registration-form__section-row registration-form__section-row_bottom">
					<div className="registration-form__section-text registration-form__section-text_right">
						<Field name="user.is_accepted_conditions_docs" type="checkbox" component={FormCheckboxField} />
					</div>
					<span className="registration-form__section-field registration-form__section-text-documents">
						Я ознакомлен(а) с{' '}
						<a href={ORGANIZATION_DOCUMENTS.NOTIFICATION} target="_blank" rel="noopener noreferrer">
							Уведомлением
						</a>{' '}
						об обработке файлов <br />
						cookie и{' '}
						<a href={ORGANIZATION_DOCUMENTS.CONDITIONS} target="_blank" rel="noopener noreferrer">
							Условиями
						</a>{' '}
						использования сервиса MAK-Logic <br />и выражаю свое{' '}
						<span className="registration-form__section-text-documents registration-form__section-text-documents_accent">
							согласие
						</span>{' '}
						с вышеуказанными <br /> Условиями.
					</span>
				</div>
			</section>
		</div>
	);
};

RegistrationFormView.propTypes = {
	companiesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isCompaniesLoading: PropTypes.bool.isRequired,
	onCompanySearchChange: PropTypes.func.isRequired,
	onCompanyChange: PropTypes.func.isRequired,
	companiesSearchValue: PropTypes.string.isRequired,
};
