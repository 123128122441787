import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocalStorageItem } from '../../utils/localStorage';
import { CompanyAccreditedView } from './company-accredited-view';

const CompanyAccredited = () => {
	const history = useHistory();

	useEffect(() => {
		const user_data = getLocalStorageItem('user_data');

		if (history.location.pathname.includes('/company/accredited') && user_data.isCompanyAccredited) {
			history.push('/home/information');
		}
	}, []);

	return <CompanyAccreditedView />;
};

export { CompanyAccredited };
