import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RelationshipFormView } from './relationship-form-view';
import { validate } from '../../utils';
import { FORM_NAME } from '../../constants';
import {
	getFormInitialValuesSelector,
	getIsAdminSelector,
	getIsOptionsListLoadingSelector,
	getIsRelationshipInfoLoadingSelector,
	getOptionsListSelector,
} from '../../selectors';

const RelationshipFormWrapper = ({ optionsList, isOptionsListLoading, isRelationshipInfoLoading, isAdmin }) => {
	return (
		<RelationshipFormView
			optionsList={optionsList}
			isOptionsListLoading={isOptionsListLoading}
			isRelationshipInfoLoading={isRelationshipInfoLoading}
			isAdmin={isAdmin}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
	keepDirtyOnReinitialize: true,
})(RelationshipFormWrapper);

const mapStateToProps = createStructuredSelector({
	optionsList: getOptionsListSelector(),
	isOptionsListLoading: getIsOptionsListLoadingSelector(),
	isRelationshipInfoLoading: getIsRelationshipInfoLoadingSelector(),
	isAdmin: getIsAdminSelector(),
	initialValues: getFormInitialValuesSelector(),
});

const RelationshipForm = connect(mapStateToProps)(withForm);

RelationshipFormWrapper.propTypes = {
	optionsList: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				key: PropTypes.string.isRequired,
			}),
		),
	),
	isOptionsListLoading: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
	isRelationshipInfoLoading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
};

export { RelationshipForm };
