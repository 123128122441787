import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'user-role-form';
export const USER_FORM_NAME = 'user-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABLE_COLUMNS_NAMES = [
	{ id: 'access', name: 'Доступ' },
	{ id: 'create', name: 'Создание' },
	{ id: 'update', name: 'Изменение' },
	{ id: 'delete', name: 'Удаление' },
	{ id: 'export', name: 'Экспорт' },
	{ id: 'notifications', name: 'Уведомления' },
];

export const TABLE_INFO_COLUMNS_NAMES = [
	{ id: 'title', width: '50%' },
	{ id: 'value', customComponent: CustomModalCell },
];

export const TABLE_INFO_ROWS_NAMES = {
	user_count: 'Кол. пользователей',
	author: 'Автор',
};

export const FIELDS_FOR_VALIDATE = ['name'];
