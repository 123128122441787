/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Field } from 'redux-form';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';
import { CustomDatePicker } from '../../../../semantic-ui/components/custom-datepicker';
import { KgIcon, PieceIcon, VolumeIcon } from '../../../../components/text-icon';
import WeightIcon from '../../../../components/icons/weight-icon.svg';
import CountIcon from '../../../../components/icons/count-icon.svg';
import SizeIcon from '../../../../components/icons/size-icon.svg';
import AddButton from '../../../../components/images/add-button.svg';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import { FormTextAreaField } from '../../../../semantic-ui/components/form-textarea-field';
import { normalizeNumber } from '../../../../utils/normalizeNumber';
import { normalizeInteger } from '../../../../utils/normalizeInteger';

export const PointsComponent = ({
	fields,
	partners,
	onPartnerChange,
	onPartnerSearchChange,
	partnerSearchValue,
	onAddPartnerButtonClick,
	addresses,
	onAddressChange,
	onAddressSearchChange,
	addressSearchValue,
	onAddAddressButtonClick,
	onPointChange,
	contacts,
	onContactChange,
	onContactSearchChange,
	contactSearchValue,
	onAddContactButtonClick,
	isPrr,
	onPointDelete,
	onPointAdd,
	isAvailablePartners,
	isAvailableAddresses,
	isAvailableContacts,
}) => {
	return fields.map((name, index) => {
		// first point is point from, it render outside this component
		if (index === 0) {
			return null;
		}

		return (
			<section className="req-form__section" key={name}>
				<div className="req-form__section-header">
					<h5 className="req-form__header-text">Информация о грузополучателе №{index}</h5>
					{index !== 1 && index === fields.length - 1 && (
						<div
							className="req-form__column_close"
							onClick={() => {
								onPointDelete(index);
								fields.remove(index);
							}}
						/>
					)}
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Грузополучатель</span>
					<div
						className={`req-form__field req-form__field_${isAvailablePartners ? 'with' : 'without'}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name={`${name}.partner.id`}
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите грузополучателя"
								options={partners[index] || []}
								loading={false}
								noResultsMessage="Неизвестная организация"
								onChange={value => onPartnerChange(value, index)}
								search
								onSearchChange={value => onPartnerSearchChange(value, index)}
								searchValue={partnerSearchValue[index]}
								isWithoutSelectOnBlur
							/>
						</div>
						{isAvailablePartners && (
							<div className="req-form__add-button" onClick={() => onAddPartnerButtonClick(index)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Адрес</span>
					<div
						className={`req-form__field req-form__field_${
							isAvailableAddresses ? 'with' : 'without'
						}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name={`${name}.address.id`}
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите адрес"
								options={addresses[index] || []}
								loading={false}
								noResultsMessage="Неизвестный адрес"
								onChange={value => onAddressChange(value, index)}
								search
								onSearchChange={value => onAddressSearchChange(value, index)}
								searchValue={addressSearchValue[index]}
								isWithoutSelectOnBlur
							/>
						</div>{' '}
						{isAvailableAddresses && (
							<div className="req-form__add-button" onClick={() => onAddAddressButtonClick(index)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Дата доставки</span>
					<div className="req-form__field req-form__date-field">
						<Field
							name={`${name}.date`}
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							onChange={value => onPointChange(value, index, 'date')}
							placeholder=". . / . . / . ."
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Контактное лицо</span>
					<div
						className={`req-form__field req-form__field_${isAvailableContacts ? 'with' : 'without'}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name={`${name}.contact.id`}
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите контактное лицо"
								options={contacts[index] || []}
								loading={false}
								noResultsMessage="Неизвестный контакт"
								onChange={value => onContactChange(value, index)}
								search
								onSearchChange={value => onContactSearchChange(value, index)}
								searchValue={contactSearchValue[index]}
								isWithoutSelectOnBlur
							/>
						</div>
						{isAvailableContacts && (
							<div className="req-form__add-button" onClick={() => onAddContactButtonClick(index)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Габаритные характеристики</span>
					<div className="req-form__row-with-icons">
						<div className="req-form__column">
							<div className="req-form__cargo-field">
								<img src={WeightIcon} alt="weight-icon" />
								<div className="req-form__field_small">
									<Field
										name={`${name}.weight`}
										type="text"
										normalize={normalizeNumber}
										component={FormInputField}
										autoComplete="off"
										icon={KgIcon}
										placeholder="Масса"
										onChange={e => onPointChange(normalizeNumber(e.target.value), index, 'weight')}
										isWithoutErrorText
									/>
								</div>
							</div>
						</div>
						<div className="req-form__column">
							<div className="req-form__cargo-field">
								<img src={SizeIcon} alt="weight-icon" />
								<div className="req-form__field_small">
									<Field
										name={`${name}.volume`}
										type="text"
										normalize={normalizeNumber}
										component={FormInputField}
										autoComplete="off"
										icon={VolumeIcon}
										placeholder="Объем"
										onChange={e => onPointChange(normalizeNumber(e.target.value), index, 'volume')}
										isWithoutErrorText
									/>
								</div>
							</div>
						</div>
						<div className="req-form__column">
							<div className="req-form__cargo-field">
								<img src={CountIcon} alt="count-icon" />
								<div className="req-form__field_small">
									<Field
										name={`${name}.place`}
										type="text"
										normalize={normalizeInteger}
										component={FormInputField}
										autoComplete="off"
										icon={PieceIcon}
										placeholder="Места"
										onChange={e => onPointChange(normalizeInteger(e.target.value), index, 'place')}
										isWithoutErrorText
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="req-form__row__error">
					<Field
						name={`${name}.weight`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="req-form__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
					<Field
						name={`${name}.volume`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="req-form__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
					<Field
						name={`${name}.place`}
						component={({ meta }) => {
							if (meta.error && meta.touched) {
								return <span className="req-form__error-message">{meta.error}</span>;
							}

							return null;
						}}
					/>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__checkbox">
						<Field
							name={`${name}.is_prr`}
							component={FormCheckboxField}
							toggle
							type="checkbox"
							onChange={value => onPointChange(value, index, 'is_prr')}
						/>
					</div>
					<span className="req-form__label">Требуются погрузочно разгрузочные работы (ПРР)</span>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Примечание к ПРР</span>
					<div className="req-form__field">
						<Field
							name={`${name}.description`}
							type="text"
							component={FormTextAreaField}
							autoComplete="off"
							placeholder="Укажите примечание к ПРР"
							width="100%"
							resize="vertical"
							disabled={!isPrr.points[index]}
							onChange={e => onPointChange(e.target.value, index, 'description')}
						/>
					</div>
				</div>
				{index === fields.length - 1 && (
					<div className="req-form__button-row">
						<div className="req-form__button-row-text">Добавить точку доставки</div>
						<img src={AddButton} alt="add-button" onClick={() => onPointAdd(fields)} />
					</div>
				)}
			</section>
		);
	});
};
