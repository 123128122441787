import { CustomModalCell } from '../../components/custom-modal-cell';
import { CustomTrailerCell } from './components/custom-trailer-cell';

export const FORM_NAME = 'trailers-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TRAILER_TABLE_COLUMNS_NAMES = [
	{ id: 'title' },
	{ id: 'value', customComponent: CustomModalCell },
	{ id: 'mode', customComponent: CustomTrailerCell },
];

export const TRAILER_TABLE_ROWS_NAMES = {
	number: 'Гос. номер',
	vin: 'Vin номер',
	type: 'Тип прицепа',
	dateIssue: 'Год выпуска прицепа',
	typeDateIssue: 'Год выпуска рефрижератора',
	tonnage: 'Грузоподъемность',
	isAccredited: 'Прицеп аккредитован',
	author: 'Автор',
};

export const FIELDS_FOR_VALIDATE = ['name', 'number', 'type_id', 'date_issue'];

export const FORM_FIELDS = ['name', 'number', 'vin', 'type_id', 'tonnage_id', 'date_issue', 'type_date_issue', 'modes'];

export const TYPES_WITH_DATE_ISSUE_AND_MODES = ['1'];
