/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { getContactsAction, resetStateAction, setContactsSearchValueAction } from './actions';
import {
	getContactsSearchValueSelector,
	getContactsSelector,
	getIsContactsLoadingSelector,
	getPaginationSelector,
} from './selectors';
import { TableComponent } from '../../components/table-component';
import { CONTACTS_TABLE_COLUMNS } from './constants';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { ModalHeader } from './components/modal-header';
import { resetStateAction as resetAddressModalStateAction } from '../address-modal/actions';
import { resetStateAction as resetAddressStateAction } from '../addresses/actions';
import { getIsAvailableContactsButtonsSelector } from '../profile/selectors';
import { getIsAuditTabShownSelector } from '../address-modal/selectors';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';

const ContactsContainerWrapper = ({
	onGetContacts,
	contacts,
	contactsSearchValue,
	onSetContactsSearchValue,
	onResetState,
	pagination,
	isChildren,
	onResetAddressModalState,
	onResetAddressState,
	isAvailable,
	isContactsLoading,
	isAuditTabShown,
}) => {
	useInjectSaga({ key: 'contactsSaga', saga });
	useInjectReducer({ key: 'contactsReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const modal = query.get('modal');

	const id = pathname.includes('partners') ? query.get('addressId') : query.get('id');

	const mode = query.get('mode');

	useEffect(() => {
		if (!contacts.length) {
			onGetContacts({ searchQuery: query.toString(), id });
		}
	}, []);

	useEffect(() => {
		if (query.has('contactsSearch')) {
			onSetContactsSearchValue(query.get('contactsSearch').toString());
		}
	}, []);

	const handleAddButtonClick = () => {
		query.set('modal', 'contact');
		query.set('mode', 'add');

		history.push(`${pathname}?${query}`);
	};

	const handleKeyPress = ({ key }) => {
		if (key === 'Enter') {
			query.set('page', 1);

			if (contactsSearchValue) {
				query.set('contactsSearch', contactsSearchValue);
			} else {
				query.delete('contactsSearch');
			}

			history.push(`${pathname}?${query}`);
			onGetContacts({ searchQuery: query.toString(), id });
		}
	};

	const handleSearchInputChange = ({ target: { value } }) => onSetContactsSearchValue(value);

	const handleSearchInputClear = () => {
		onSetContactsSearchValue('');

		query.delete('contactsSearch');
		history.push(`${pathname}?${query}`);
		onGetContacts({ searchQuery: query.toString(), id });
	};

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);
		onGetContacts({ searchQuery: query.toString(), id });
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
			history.push(`${pathname}?${query}`);
		} else {
			query.set('per_page', value);
			history.push(`${pathname}?${query}`);
		}
		onGetContacts({ searchQuery: query.toString(), id });
	};

	const handleNavItemClick = (modal, tab) => {
		if (modal === 'address') {
			onResetState();
		}

		query.set('modal', modal);

		if (tab) {
			query.set('tab', tab);
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('contactsSearch');
		query.delete('addressId');

		onResetState();
		onResetAddressModalState();
		if (pathname.includes('partners')) {
			onResetAddressState();
		}
		history.push(`${pathname}?${query}`);
	};

	return (
		<ModalWindow
			isModalShown
			navItems={[
				{
					id: 'info',
					text: 'Информация',
					onClick: () => handleNavItemClick('address'),
					isActive: modal === 'address',
				},
				{
					id: 'contacts',
					text: 'Контактные лица',
					onClick: () => handleNavItemClick('contacts'),
					isActive: modal === 'contacts',
				},
				{
					id: 'audit',
					text: 'Изменения',
					onClick: () => handleNavItemClick('address', 'audit'),
					isActive: modal === 'address' && !mode && isAuditTabShown,
				},
			]}
			modalContent={
				<TableComponent
					headerText="Контактные лица"
					onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
					columns={CONTACTS_TABLE_COLUMNS}
					rows={contacts}
					onSearch={handleKeyPress}
					isTableDataLoading={isContactsLoading}
					pagination={
						isChildren
							? null
							: { ...pagination, onPageChange: handlePageChange, onSelectChange: handleSelectChange }
					}
					searchInputValue={contactsSearchValue}
					onSearchInputChange={handleSearchInputChange}
					onSearchInputClear={handleSearchInputClear}
					isChildren={isChildren}
					isWithoutFooter={isChildren}
				/>
			}
			modalHeader={
				<ModalHeader
					onKeyPress={handleKeyPress}
					searchInputValue={contactsSearchValue}
					onSearchInputChange={handleSearchInputChange}
					onSearchInputClear={handleSearchInputClear}
					onAddButtonClick={isAvailable.create ? handleAddButtonClick : null}
					onModalClose={handleModalClose}
				/>
			}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							onClick: handleModalClose,
							text: 'Закрыть',
							color: 'secondary',
						},
					]}
				/>
			}
			style={{ width: '900px' }}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	contacts: getContactsSelector(),
	contactsSearchValue: getContactsSearchValueSelector(),
	pagination: getPaginationSelector(),
	isAvailable: getIsAvailableContactsButtonsSelector(),
	isContactsLoading: getIsContactsLoadingSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetContacts: getContactsAction,
	onSetContactsSearchValue: setContactsSearchValueAction,
	onResetState: resetStateAction,
	onResetAddressModalState: resetAddressModalStateAction,
	onResetAddressState: resetAddressStateAction,
};

ContactsContainerWrapper.propTypes = {
	onGetContacts: PropTypes.func.isRequired,
	contacts: PropTypes.array.isRequired,
	contactsSearchValue: PropTypes.string.isRequired,
	onSetContactsSearchValue: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	onResetAddressModalState: PropTypes.func.isRequired,
	onResetAddressState: PropTypes.func.isRequired,
	isContactsLoading: PropTypes.bool.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	// Определяет открывается ли контейнер в окне или нет
	isChildren: PropTypes.bool,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	isAuditTabShown: PropTypes.bool.isRequired,
};

const ContactsContainer = connect(mapStateToProps, mapDispatchToProps)(ContactsContainerWrapper);

export { ContactsContainer };
