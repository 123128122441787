/* eslint-disable no-mixed-spaces-and-tabs */
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { getRelationshipListRequest } from '../../api/requests';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import {
	GET_RELATIONSHIP_LIST,
	setIsRelationshipListLoadingAction,
	setPaginationAction,
	setRelationshipListAction,
} from './actions';
import { errorHandler } from '../../api/utils';

export function* getRelationshipListSaga({ payload: searchQuery }) {
	try {
		yield put(setIsRelationshipListLoadingAction(true));
		const { data, meta, message, toast } = yield call(getRelationshipListRequest, searchQuery);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setRelationshipListAction(
					data?.map(relationship => ({
						...relationship,
						link: `/companies_relationship?${searchQuery ? `${searchQuery}&` : ''}modal=relationship&id=${
							relationship.id
						}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipListLoadingAction(false));
	}
}

export default function* relationshipContainerSaga() {
	yield takeEvery(GET_RELATIONSHIP_LIST, getRelationshipListSaga);
}
