/* eslint-disable no-mixed-spaces-and-tabs */
import { call, debounce, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { stopSubmit, touch, change } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import {
	ADD_CONTACT_FORM_NAME,
	ADD_PARTNER_FORM_NAME,
	CONTACT_FORM_REQUIRED_FIELDS,
	REQUEST_ERROR_MESSAGE,
	PARTNER_FORM_REQUIRED_FIELDS,
	ADD_ADDRESS_FORM_NAME,
	ADDRESS_FORM_REQUIRED_FIELDS,
	REQUEST_FORM_NAME,
	REQUEST_FORM_FIELDS_NAMES,
	AVIA_REQUEST_FORM_FIELDS_NAMES,
} from './constants';
import {
	GET_CONTACTS,
	GET_PARTNERS,
	GET_ADDRESSES,
	setContactsAction,
	setAddressesAction,
	setPartnersAction,
	setModesAction,
	setTypesAction,
	setIsDictionariesLoadingAction,
	GET_DICTIONARIES,
	GET_PARTNERS_WITH_DEBOUNCE,
	GET_PARTNERS_FORM_OPTIONS,
	setIsPartnersFormOptionsLoadingAction,
	setPartnersFormOptionsAction,
	setIsPartnerCreatingAction,
	CREATE_PARTNER,
	GET_ADDRESS_FORM_OPTIONS,
	setAddressFormOptionsAction,
	setIsAddressFormOptionsLoadingAction,
	setIsContactCreatingAction,
	CREATE_CONTACT,
	GET_REQUEST_INFO,
	setRequestInfoAction,
	setIsRequestInfoLoadingAction,
	setIsAddressCreatingAction,
	CREATE_ADDRESS,
	DELETE_REQUEST,
	UPDATE_REQUEST,
	CREATE_REQUEST,
	GET_ADDRESS_WITH_DEBOUNCE,
	GET_CONTACT_WITH_DEBOUNCE,
	getAddressesAction,
	getContactsAction,
	setPartnersSearchValueAction,
	setAddressFormSearchValueAction,
	getPartnersAction,
	setFeaturesAction,
	setRatesAction,
	setTenderTypesAction,
	setTenderCompaniesAction,
} from './actions';
import { DISMISS_TIME } from '../../api/constants';
import {
	createPartnerAddressRequest,
	createPartnerRequest,
	getPartnerAddressesRequest,
	getPartnersBySearchRequest,
	getPartnersRequest,
} from '../../api/partners-requests';
import {
	getTypesDictionaryRequest,
	getContactsRequest,
	getModesDictionaryRequest,
	getAddressesBySearchRequest,
	createContactRequest,
	deleteReqRequest,
	createMyReqRequest,
	updateMyReqRequest,
	recreateReqRequest,
	getFeaturesDictionaryRequest,
	getRatesDictionaryRequest,
	getTenderTypesRequest,
	getTenderCompaniesRequest,
} from '../../api/requests';
import {
	getActivePointIndexSelector,
	getAddressFormIsInvalidSelector,
	getAddressFormSearchValueSelector,
	getAddressFormValuesSelector,
	getAddressSearchValueSelector,
	getAddressesSelector,
	getContactFormIsInvalidSelector,
	getContactFormValuesSelector,
	getContactSearchValueSelector,
	getContactsSelector,
	getDataForSaveSelector,
	getPartnerFormIsInvalidSelector,
	getPartnerFormValuesSelector,
	getPartnerSearchValueSelector,
	getPartnersFormSearchValueSelector,
	getPartnersSelector,
	getPointsSelector,
	getRequestFormInitialValuesSelector,
	getRequestFormIsInvalidSelector,
} from './selectors';
import { transformToValidationErrors } from './utils';
import { getRequestInfoRequest } from '../../api/reqs-requests';
import { getRawPaginationSelector, getReqsSelector } from '../reqs-list/selectors';
import { setPaginationAction, setReqsAction } from '../reqs-list/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getRequestInfoSaga({ payload: { id, mode } }) {
	try {
		yield put(setIsRequestInfoLoadingAction(true));

		const request = mode === 'recreate' ? recreateReqRequest : getRequestInfoRequest;

		const { data, message, toast } = yield call(request, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setRequestInfoAction({
					...data,
					mode: data.mode?.id,
					type: data.type?.id,
					rate: data.rate?.id,
					tender_type_id: data.tender_type?.id,
					tender_tk_id: data.tender_tk?.id,
					points: data.points.map(point => ({
						...point,
						date: point.date ? moment(point.date).format('YYYY-MM-DD') : '',
					})),
				}),
			);

			yield put(getPartnersAction());

			const addresses = data.points.reduce((acc, point, index) => {
				acc[index] = [point.address];

				return acc;
			}, {});

			for (let i = 0; i < data.points?.length || 0; i++) {
				if (data.points?.[i]?.partner?.id) {
					yield put(getAddressesAction({ id: data.points[i].partner.id, index: i }));
				}
			}

			yield put(setAddressesAction(addresses));

			const contacts = data.points.reduce((acc, point, index) => {
				acc[index] = [
					{
						...point.contact,
						name: `${point.contact.last_name || ''} ${point.contact.first_name || ''} ${
							point.contact.middle_name || ''
						}`,
					},
				];

				return acc;
			}, {});

			for (let i = 0; i < data.points?.length || 0; i++) {
				if (data.points?.[i]?.address?.id) {
					yield put(getContactsAction({ id: data.points[i].address.id, index: i, notChangeDefault: true }));
				}
			}

			yield put(setContactsAction(contacts));

			if (!data?.mode?.id) {
				yield put(change(REQUEST_FORM_NAME, 'mode', 'none'));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRequestInfoLoadingAction(false));
	}
}

export function* getPartnersSaga({ payload: index }) {
	try {
		const query = yield select(getPartnerSearchValueSelector());

		const { data, message, toast } = yield call(
			getPartnersRequest,
			`${query[index] ? `?search=${query[index]}&receive=all` : '?receive=all'}`,
		);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			if (index !== undefined) {
				const partners = yield select(getPartnersSelector());

				yield put(setPartnersAction({ ...partners, [index]: data }));
			} else {
				const points = yield select(getPointsSelector());

				const newPartners = points.reduce((acc, _, index) => {
					acc[index] = data;

					return acc;
				}, {});

				yield put(setPartnersAction(newPartners));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* getAddressesSaga({ payload: { id, index } }) {
	try {
		const query = yield select(getAddressSearchValueSelector());

		const { data, message, toast } = yield call(
			getPartnerAddressesRequest,
			`${query[index] ? `?search=${query[index]}&receive=all` : '?receive=all'}`,
			id,
		);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const addresses = yield select(getAddressesSelector());

			yield put(setAddressesAction({ ...addresses, [index]: data }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* getContactsSaga({ payload: { id, index, notChangeDefault } }) {
	try {
		const query = yield select(getContactSearchValueSelector());

		const { data, message, toast } = yield call(
			getContactsRequest,
			`${query[index] ? `?search=${query[index]}&receive=all` : '?receive=all'}`,
			id,
		);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const contacts = yield select(getContactsSelector());

			yield put(
				setContactsAction({
					...contacts,
					[index]: data?.map(contact => ({
						...contact,
						name: `${contact.last_name || ''} ${contact.first_name || ''} ${contact.middle_name || ''}`,
					})),
				}),
			);

			const defaultContact = data.find(contact => contact.is_default);

			if (defaultContact && !notChangeDefault) {
				const info = yield select(getRequestFormInitialValuesSelector());

				const newPoints = [...(info.points || [])];

				newPoints[index] = {
					...(info.points?.[index] || {}),
					contact: defaultContact,
				};

				yield put(setRequestInfoAction({ ...info, points: newPoints }));
				yield put(change(REQUEST_FORM_NAME, `points.${index}.contact`, defaultContact));
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* getModesSaga() {
	try {
		yield put(setIsDictionariesLoadingAction(true));

		const { data, message, toast } = yield call(getModesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setModesAction([
					{ text: 'Отсутствует', value: 'none', key: 'none' },
					...data.map(item => ({ key: item.id, value: item.id, text: item.name })),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionariesLoadingAction(false));
	}
}

export function* getTypesSaga() {
	try {
		yield put(setIsDictionariesLoadingAction(true));

		const { data, message, toast } = yield call(getTypesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setTypesAction(data.map(item => ({ key: item.id, value: item.id, text: item.name }))));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionariesLoadingAction(false));
	}
}

export function* getRatesSaga() {
	try {
		yield put(setIsDictionariesLoadingAction(true));

		const { data, message, toast } = yield call(getRatesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setRatesAction(data.map(item => ({ key: item.id, value: item.id, text: item.name }))));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionariesLoadingAction(false));
	}
}

export function* getFeaturesSaga() {
	try {
		yield put(setIsDictionariesLoadingAction(true));

		const { data, message, toast } = yield call(getFeaturesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setFeaturesAction(data.sort((a, b) => a.id - b.id)));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionariesLoadingAction(false));
	}
}

export function* getTenderTypesSaga() {
	try {
		yield put(setIsDictionariesLoadingAction(true));

		const { data, message, toast } = yield call(getTenderTypesRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setTenderTypesAction(data.map(item => ({ key: item.id, value: item.id, text: item.name }))));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionariesLoadingAction(false));
	}
}

export function* getTenderCompaniesSaga() {
	try {
		yield put(setIsDictionariesLoadingAction(true));

		const { data, message, toast } = yield call(getTenderCompaniesRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setTenderCompaniesAction(data.map(item => ({ key: item.id, value: item.id, text: item.name }))));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDictionariesLoadingAction(false));
	}
}

export function* getPartnersOptionsSaga() {
	try {
		yield put(setIsPartnersFormOptionsLoadingAction(true));

		const searchQuery = yield select(getPartnersFormSearchValueSelector());

		const response = yield call(getPartnersBySearchRequest, searchQuery);

		if (response?.suggestions) {
			yield put(setPartnersFormOptionsAction(response.suggestions));
		} else {
			throw new Error(response?.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnersFormOptionsLoadingAction(false));
	}
}

export function* createPartnerSaga({ payload: { redirect, searchQuery } }) {
	try {
		yield put(setIsPartnerCreatingAction(true));

		const dataForSave = yield select(getPartnerFormValuesSelector());

		const isInvalid = yield select(getPartnerFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(createPartnerRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const info = yield select(getRequestFormInitialValuesSelector());

			const index = yield select(getActivePointIndexSelector());

			const newPoints = [...(info.points || [])];

			newPoints[index] = {
				...(info.points?.[index] || {}),
				partner: data,
			};

			delete newPoints[index]?.address;
			delete newPoints[index]?.contact;

			yield put(setRequestInfoAction({ ...info, points: newPoints }));

			const partners = yield select(getPartnersSelector());

			yield put(setPartnersAction({ ...partners, [index]: [...partners[index], data] }));

			const addresses = yield select(getAddressesSelector());
			yield put(setAddressesAction({ ...addresses, [index]: [] }));

			const contacts = yield select(getContactsSelector());
			yield put(setContactsAction({ ...contacts, [index]: [] }));

			yield put(setPartnersFormOptionsAction([]));
			yield put(setPartnersSearchValueAction(''));

			const query = new URLSearchParams(searchQuery);

			query.set('modal', 'request-address');

			redirect(`${window.location.pathname}?${query.toString()}`);
		} else {
			if (errors) {
				yield put(touch(ADD_PARTNER_FORM_NAME, ...PARTNER_FORM_REQUIRED_FIELDS));
				yield put(stopSubmit(ADD_PARTNER_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnerCreatingAction(false));
	}
}

export function* createAddressSaga({ payload: { redirect, searchQuery } }) {
	try {
		yield put(setIsAddressCreatingAction(true));

		const isInvalid = yield select(getAddressFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getAddressFormValuesSelector());

		const info = yield select(getRequestFormInitialValuesSelector());

		const index = yield select(getActivePointIndexSelector());

		const partnerId = info.points?.[index]?.partner?.id;

		const { data, errors, message, toast } = yield call(createPartnerAddressRequest, dataForSave, partnerId);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const newPoints = [...(info.points || [])];

			newPoints[index] = {
				...(info.points?.[index] || {}),
				address: data,
			};

			delete newPoints[index]?.contact;

			const contacts = yield select(getContactsSelector());
			yield put(setContactsAction({ ...contacts, [index]: [] }));

			yield put(setRequestInfoAction({ ...info, points: newPoints }));

			const addresses = yield select(getAddressesSelector());

			yield put(setAddressesAction({ ...addresses, [index]: [...addresses[index], data] }));

			yield put(setAddressFormOptionsAction([]));
			yield put(setAddressFormSearchValueAction(''));

			const query = new URLSearchParams(searchQuery);

			query.set('modal', 'request-contact');

			redirect(`${window.location.pathname}?${query.toString()}`);
		} else {
			if (errors) {
				yield put(touch(ADD_ADDRESS_FORM_NAME, ...ADDRESS_FORM_REQUIRED_FIELDS));
				yield put(stopSubmit(ADD_ADDRESS_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressCreatingAction(false));
	}
}

export function* getAddressOptionsSaga() {
	try {
		yield put(setIsAddressFormOptionsLoadingAction(true));

		const searchQuery = yield select(getAddressFormSearchValueSelector());

		const response = yield call(getAddressesBySearchRequest, searchQuery);

		if (response?.suggestions) {
			yield put(setAddressFormOptionsAction(response.suggestions));
		} else {
			throw new Error(response?.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressFormOptionsLoadingAction(false));
	}
}

export function* createContactSaga({ payload: { redirect } }) {
	try {
		yield put(setIsContactCreatingAction(true));

		const dataForSave = yield select(getContactFormValuesSelector());

		const isInvalid = yield select(getContactFormIsInvalidSelector());

		if (isInvalid) return;

		const info = yield select(getRequestFormInitialValuesSelector());

		const index = yield select(getActivePointIndexSelector());

		const addressId = info.points?.[index]?.address?.id;

		const { data, errors, message, toast } = yield call(createContactRequest, dataForSave, addressId);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const newPoints = [...(info.points || [])];

			newPoints[index] = {
				...(info.points?.[index] || {}),
				contact: data,
			};

			yield put(setRequestInfoAction({ ...info, points: newPoints }));

			const contacts = yield select(getContactsSelector());

			yield put(
				setContactsAction({
					...contacts,
					[index]: [
						...contacts[index],
						{ ...data, name: `${data.last_name || ''} ${data.first_name || ''} ${data.middle_name || ''}` },
					],
				}),
			);

			redirect();
		} else {
			if (errors) {
				yield put(touch(ADD_CONTACT_FORM_NAME, ...CONTACT_FORM_REQUIRED_FIELDS));
				yield put(stopSubmit(ADD_CONTACT_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsContactCreatingAction(false));
	}
}

export function* deleteRequestSaga({ payload: { id, pathname } }) {
	try {
		const { data, toast, message } = yield call(deleteReqRequest, id, pathname);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const reqs = yield select(getReqsSelector());

			const newReqs = reqs.filter(req => {
				return req.id !== data.id;
			});

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			yield put(setReqsAction(newReqs));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* updateRequestSaga({ payload: { id, searchQuery, redirect, pathname } }) {
	try {
		const isInvalid = yield select(getRequestFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForSaveSelector());

		const { data, toast, message, errors } = yield call(updateMyReqRequest, id, '', dataForSave, pathname);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? {
									link: `${toast?.link}${
										removeModalQuery(searchQuery.toString())
											? `&${removeModalQuery(searchQuery.toString())}`
											: ''
									}`,
							  }
							: {}),
					}),
				);
			}

			const reqs = yield select(getReqsSelector());

			const updatedReq = {
				...data,
				link: `${window.location.pathname}?${
					removeModalQuery(searchQuery.toString()) ? `${removeModalQuery(searchQuery.toString())}&` : ''
				}modal=reqs_details&id=${data.id}`,
				additionalPoints: data.points.length - 2,
				renderPoints: [data.points[0], data.points.at(-1)],
			};

			const newReqs = reqs.map(req => {
				return req.id === data.id ? updatedReq : req;
			});

			yield put(setReqsAction(newReqs));
			redirect();
		} else {
			if (errors) {
				const fields = window.location.pathname.includes('avia')
					? AVIA_REQUEST_FORM_FIELDS_NAMES
					: REQUEST_FORM_FIELDS_NAMES;

				yield put(touch(REQUEST_FORM_NAME, ...fields));
				yield put(stopSubmit(REQUEST_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* createRequestSaga({ payload: { searchQuery, redirect, pathname, mode } }) {
	try {
		const dataForSave = yield select(getDataForSaveSelector());

		if (mode === 'recreate') {
			delete dataForSave.id;
		}

		searchQuery.delete('mode');
		searchQuery.delete('id');
		searchQuery.delete('modal');

		const isInvalid = yield select(getRequestFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, toast, message, errors } = yield call(createMyReqRequest, dataForSave, pathname);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? { link: `${toast?.link}${searchQuery.toString() ? `&${searchQuery.toString()}` : ''}` }
							: {}),
					}),
				);
			}

			// if included in routes, add to list
			if (data.routes.includes(window.location.pathname)) {
				const reqs = yield select(getReqsSelector());

				const updatedReq = {
					...data,
					link: `${window.location.pathname}?${searchQuery ? `${searchQuery}&` : ''}modal=reqs_details&id=${
						data.id
					}`,
					additionalPoints: data.points.length - 2,
					renderPoints: [data.points[0], data.points.at(-1)],
				};

				yield put(setReqsAction([updatedReq, ...reqs]));
			}

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			}

			redirect();
		} else {
			if (errors) {
				const fields = window.location.pathname.includes('avia')
					? AVIA_REQUEST_FORM_FIELDS_NAMES
					: REQUEST_FORM_FIELDS_NAMES;

				yield put(touch(REQUEST_FORM_NAME, ...fields));
				yield put(stopSubmit(REQUEST_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export default function* requestFormSaga() {
	yield takeEvery(GET_REQUEST_INFO, getRequestInfoSaga);
	yield takeEvery(GET_PARTNERS, getPartnersSaga);
	yield debounce(300, GET_PARTNERS_WITH_DEBOUNCE, getPartnersSaga);
	yield takeEvery(GET_ADDRESSES, getAddressesSaga);
	yield debounce(300, GET_ADDRESS_WITH_DEBOUNCE, getAddressesSaga);
	yield takeEvery(GET_CONTACTS, getContactsSaga);
	yield debounce(300, GET_CONTACT_WITH_DEBOUNCE, getContactsSaga);
	yield takeEvery(GET_DICTIONARIES, getModesSaga);
	yield takeEvery(GET_DICTIONARIES, getTypesSaga);
	yield takeEvery(GET_DICTIONARIES, getFeaturesSaga);
	yield takeEvery(GET_DICTIONARIES, getRatesSaga);
	yield takeEvery(GET_DICTIONARIES, getTenderTypesSaga);
	yield takeEvery(GET_DICTIONARIES, getTenderCompaniesSaga);
	yield debounce(300, GET_PARTNERS_FORM_OPTIONS, getPartnersOptionsSaga);
	yield takeEvery(CREATE_PARTNER, createPartnerSaga);
	yield debounce(300, GET_ADDRESS_FORM_OPTIONS, getAddressOptionsSaga);
	yield takeEvery(CREATE_CONTACT, createContactSaga);
	yield takeEvery(CREATE_ADDRESS, createAddressSaga);
	yield takeEvery(DELETE_REQUEST, deleteRequestSaga);
	yield takeEvery(UPDATE_REQUEST, updateRequestSaga);
	yield takeEvery(CREATE_REQUEST, createRequestSaga);
}
