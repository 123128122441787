import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TrailerFormView } from './trailer-form-view';
import {
	getTrailerTypesSelector,
	getIsAdminSelector,
	getIsTrailerInfoLoadingSelector,
	getIsTrailerTypesLoadingSelector,
	getIsModesLoadingSelector,
	getIsTonnagesLoadingSelector,
	getIsTypeDateIssueVisibleSelector,
	getModesSelector,
	getTonnagesSelector,
} from '../../selectors';

export const TrailerFormWrapper = ({
	isTrailerInfoLoading,
	tonnages,
	isTonnagesLoading,
	trailerTypes,
	isTypeDateIssueVisible,
	modes,
	isAdmin,
	isTrailerTypesLoading,
	isModesLoading,
}) => {
	return (
		<TrailerFormView
			isTrailerInfoLoading={isTrailerInfoLoading}
			tonnages={tonnages}
			isTonnagesLoading={isTonnagesLoading}
			trailerTypes={trailerTypes}
			isTypeDateIssueVisible={isTypeDateIssueVisible}
			modes={modes}
			isAdmin={isAdmin}
			isTrailerTypesLoading={isTrailerTypesLoading}
			isModesLoading={isModesLoading}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isTrailerInfoLoading: getIsTrailerInfoLoadingSelector(),
	tonnages: getTonnagesSelector(),
	isTonnagesLoading: getIsTonnagesLoadingSelector(),
	trailerTypes: getTrailerTypesSelector(),
	isTypeDateIssueVisible: getIsTypeDateIssueVisibleSelector(),
	modes: getModesSelector(),
	isAdmin: getIsAdminSelector(),
	isTrailerTypesLoading: getIsTrailerTypesLoadingSelector(),
	isModesLoading: getIsModesLoadingSelector(),
});

TrailerFormWrapper.propTypes = {
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	trailerTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isTypeDateIssueVisible: PropTypes.bool.isRequired,
	modes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isAdmin: PropTypes.bool.isRequired,
	isTrailerTypesLoading: PropTypes.bool.isRequired,
	isModesLoading: PropTypes.bool.isRequired,
};

const TrailerForm = connect(mapStateToProps)(TrailerFormWrapper);

export { TrailerForm };
