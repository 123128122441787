import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME, WAY_TABLE_ROWS_NAMES } from './constants';
import { getDictionaryDataAsObjectSelector as periodDictionarySelector } from '../dictionaries/periods/selectors';

export const selectWaysModalDomain = state => state.waysModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getWayInfoSelector = () =>
	createSelector(selectWaysModalDomain, ({ wayInfo }) => {
		if (!wayInfo) {
			return [];
		}

		const points = {};

		const dates = {
			address_from: wayInfo?.points ? wayInfo?.points[0]?.date : '---',
		};

		wayInfo?.points.slice(1).forEach((el, index) => {
			points[`address_to${index + 2}`] = el?.address;
			dates[`address_to${index + 2}`] = el?.date;
			WAY_TABLE_ROWS_NAMES[`address_to${index + 2}`] = `Адрес доставки #${index + 1}`;
		});

		const data = {
			is_regular: wayInfo.is_regular ? 'Да' : 'Нет',
			period: wayInfo?.period?.name,
			address_from: wayInfo?.points ? wayInfo?.points[0]?.address : '---',
			...points,
			weight: `${String(wayInfo?.weight)} кг.`,
			volume: `${String(wayInfo?.volume)} м³`,
			place: `${String(wayInfo?.place)} шт.`,
			temperature: wayInfo?.modes ? `${wayInfo?.modes.map(el => el.name).join(', ')} °C` : '---',
			author: {
				name: `${wayInfo?.user?.last_name || '-'} ${wayInfo?.user?.first_name || '-'}`,
				role: wayInfo?.user?.role.name || '-',
			},
		};

		return Object.entries(data).reduce((result, [key, value]) => {
			let date = dates[key];
			if (date) {
				date = new Date(date).toLocaleDateString('ru-RU');
			}

			return [...result, { id: key, title: WAY_TABLE_ROWS_NAMES[key], value, date }];
		}, []);
	});

export const getIsWayInfoLoadingSelector = () =>
	createSelector(
		selectWaysModalDomain,
		({ isWayInfoLoading, isWayDeleting, isWaySaving }) => isWayInfoLoading || isWayDeleting || isWaySaving,
	);

export const getWayNameSelector = () => createSelector(selectWaysModalDomain, ({ wayInfo }) => wayInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'way');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'way' && query?.tab === 'audit');
	});

export const getActiveWayIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'way' ? query?.id : null));

export const getIsWayAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'way' && query?.mode === 'add');
	});

export const getIsWayEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'way' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectWaysModalDomain, getFormValues(FORM_NAME), ({ wayInfo, modes }, formValues) => {
		if (!wayInfo && !formValues) {
			return {
				period: 'none',
				address_to: [{ address: '', date: '' }],
				address_from: '',
			};
		}

		if (!wayInfo && formValues) {
			return formValues;
		}

		const data = {
			name: wayInfo.name,
			is_regular: wayInfo.is_regular,
			period: wayInfo?.period?.name,
			address_from: wayInfo?.points ? wayInfo?.points[0]?.address : '-',
			address_to: [{ address: '', date: '' }],
			weight: wayInfo?.weight,
			volume: wayInfo?.volume,
			place: wayInfo?.place,
			temperature: wayInfo?.modes ? wayInfo?.modes.map(el => el.name).join(', ') : '-',
		};

		if (wayInfo?.modes) {
			data.modes = modes.reduce(
				(arr, el) => {
					if (wayInfo.modes.find(mode => mode.id === el.id)) {
						arr.push(true);
					} else {
						arr.push(false);
					}

					return arr;
				},
				[false],
			);
		}
		if (wayInfo?.points) {
			data.address_from = wayInfo?.points?.[0]?.address;
			data.date_from = wayInfo?.points?.[0]?.date;
			data.address_to = wayInfo?.points?.slice(1, wayInfo?.points?.length);
		}

		return data;
	});

export const getFormValuesSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => ({
		...formValues,
	}));

export const getDataForUpdateSelector = () =>
	createSelector(
		selectWaysModalDomain,
		getFormValues(FORM_NAME),
		periodDictionarySelector(),
		({ addresses, wayInfo }, formValues, periodOptions) => {
			return {
				...formValues,
				mode_ids: formValues?.modes?.reduce((acc, item, index) => (item ? [...acc, index] : acc), []) || [],
				period_id: formValues.is_regular ? periodOptions[formValues?.period] : null,
				points: [
					{
						address: formValues?.address_from,
						...(addresses.address_from?.find(el => el.value === formValues?.address_from)?.data ||
							wayInfo?.points?.[0] ||
							{}),
						date: formValues?.date_from,
					},
					...(formValues?.address_to?.map((el, index) => {
						return {
							...(addresses?.[`address_to[${index}].address`]?.find(item => item.value === el?.address)
								?.data ||
								wayInfo?.points?.[index + 1] ||
								{}),
							days: formValues.is_regular ? el.days : null,
							address: el?.address,
							date: el?.date,
						};
					}) || []),
				],
			};
		},
	);

export const getAddressesOptionsSelector = () =>
	createSelector(selectWaysModalDomain, ({ addresses }) => {
		return Object.entries(addresses).reduce((acc, [key, value]) => {
			acc[key] = value.map(el => ({ value: el.value, text: el.value, key: el.value }));

			return acc;
		}, {});
	});

export const getAddressesSelector = () => createSelector(selectWaysModalDomain, ({ addresses }) => addresses);

export const getAddressesSearchValueSelector = () =>
	createSelector(selectWaysModalDomain, ({ addressesSearchValue }) => addressesSearchValue);

export const getIsAddressesLoadingSelector = () =>
	createSelector(selectWaysModalDomain, ({ isAddressesLoading }) => isAddressesLoading);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getModesSelector = () => createSelector(selectWaysModalDomain, ({ modes }) => modes);

export const getSelectedModesSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => formValues?.modes?.filter(item => item).length || 0);
