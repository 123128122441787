import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'relationship-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const ADMIN_ROLE_NUMBER = 1;

export const TABLE_COLUMNS_NAMES = [
	{ id: 'title', width: '245px' },
	{ id: 'value', customComponent: CustomModalCell },
];

export const ADMIN_TABLE_ROWS_NAMES = {
	shipper: 'Заказчик',
	carrier: 'Транспортная компания',
	contract: 'Договор №',
	insurance: 'Страхование',
	fee: 'Комиссия',
};

export const TABLE_ROWS_NAMES = {
	shipper: 'Заказчик',
	carrier: 'Транспортная компания',
	contract: 'Договор №',
};

export const DATE_FORMAT = 'DD.MM.YYYY';

export const FIELDS_FOR_VALIDATE = ['shipper', 'carrier', 'contract', 'insurance', 'fee'];

export const REQUIRED_FIELDS_FOR_VALIDATE = ['shipper', 'carrier'];
