import React from 'react';
import PropTypes from 'prop-types';
import { CAR_TABLE_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { CarForm } from '../car-form';

export const CarContent = ({ carInfo, isCarInfoLoading, isCarFormOpen }) => {
	return isCarFormOpen ? (
		<CarForm />
	) : (
		<SimpleTableComponent rows={carInfo} isTableDataLoading={isCarInfoLoading} columns={CAR_TABLE_COLUMNS_NAMES} />
	);
};

CarContent.propTypes = {
	carInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					name: PropTypes.string,
					role: PropTypes.string,
				}),
			]).isRequired,
		}),
	),
	isCarInfoLoading: PropTypes.bool.isRequired,
	isCarFormOpen: PropTypes.bool.isRequired,
};
